const ZH_CN = {
  // left description
  refundTitle: '退款与退货政策',
  refundDescOne:
    '感谢您在 S2trading Sdn.Bhd. 运营的 es2move.com 购买我们的送货服务。我们网站上的所有交付订单都有提供全额退款保证。 如果您对我们的送货服务不满意，则可以申请退款。 您有资格在购买后的 14 个工作日内要求全额退款。',
  refundDescTwo: '14 天后将不符合条规，不能够要求退款。 如果您还有其他疑问或想要退款，请随时与我们联系。',

  // right contact
  contactInfo: '您的联络资讯',
  name: '您的姓名',
  contactNumber: '您的联络电话',
  message: '讯息',
  submit: '提交',
  clear: '清除',
  moreinfo: '告诉我们更多相关的资讯',

  // snackbar
  success: '成功！您已经送出您的资讯。',
}

const EN = {
  // left description
  refundTitle: 'Refund and Refund Policy',
  refundDescOne:
    'Thanks for purchasing our delivery services at es2move.com operated by S2trading Sdn. Bhd. We offer a full money-back guarantee for all deliver orders made on our website. If you are not satisfied with the service that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 working days of your purchase.',
  refundDescTwo:
    'After the 14-day period, you will no longer be eligible and won’t be able to receive a refund. If you have any additional questions or would like to request a refund, feel free to contact us.',

  // right contact
  contactInfo: 'Your Contact Info',
  name: 'Your Name',
  contactNumber: 'Your Contact Number',
  message: 'Message',
  submit: 'Submit',
  clear: 'Clear',
  moreinfo: 'Tell us more information about you.',

  // snackbar
  success: 'Success! You have already submitted your information.',
}

export default {
  ZH_CN,
  EN,
}
