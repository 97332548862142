import axios from 'axios'
import { API_ROOT, ADMIN_TOKEN } from './constants/config'

axios.defaults.baseURL = API_ROOT
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common.Accept = 'application/json'

export const setAxiosTokens = (token) => {
  axios.defaults.headers.common.token = token
}

const Visitors = {
  getItem: () => axios.get('/visitor/items'),
  getOrderStatus: (params) => axios.get('/visitor/order/status', { params }),
  evaluate: (body) => axios.post('/visitor/evaluate', body),
}

const Order = {
  getOrder: (params) => axios.get('/order/forms', { params }),
}
export default {
  Visitors,
  Order,
}
