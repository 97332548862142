import { SET_SEARCH_BURGER, SET_BURGER_CONTENTS } from '../actions/actionTypes'
const initialState = {
  showingMobileSearchbar: false,
  showingMobileBurgerContents: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_BURGER:
      return {
        ...state,
        showingMobileSearchbar: action.showingMobileSearchbar,
      }
    case SET_BURGER_CONTENTS:
      return {
        ...state,
        showingMobileBurgerContents: action.showingMobileBurgerContents,
      }
    default:
      return state
  }
}
