import { combineReducers } from 'redux'
import { i18nReducer } from 'react-redux-i18n'

import window from './reducers/window'
import search from './reducers/search'

export default combineReducers({
  window,
  search,
  i18n: i18nReducer,
})
