import { useEffect, useState } from 'react'

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(undefined)

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const windowWidth =
        window.innerWidth < 768
          ? 'sm'
          : window.innerWidth < 1024
          ? 'md'
          : window.innerWidth < 1440
          ? 'lg'
          : 'xl'
      setWindowSize(windowWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
