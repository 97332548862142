const ZH_CN = {
  prohibitedItems: '禁止物品',

  // rule 1
  ruleOne:
    '01. 任何快递公司都严格禁止以下物品（或与描述或内容相似的任何物品）。包含此类物品的任何货运均可能被取消订单，恕不另行通知。禁止使用这些项目。',
  ruleOneBulletOne: '货币/货币/支票/硬币/现金/黄金/白银',
  ruleOneBulletTwo: '假冒/盗版物品和材料',
  ruleOneBulletThree: '驾驶证/出生证明/身份证/护照/借记卡/信用卡',
  ruleOneBulletFour: '鞭炮',
  ruleOneBulletFive: '彩票',
  ruleOneBulletSix: '武器（真假）',

  // rule 2
  ruleTwo: '02. 特殊的需求要求',
  ruleTwoBulletOne: '气雾剂',
  ruleTwoBulletTwo: '酒类',
  ruleTwoBulletThree: '任何动物（存活或死亡）',
  ruleTwoBulletFour: '动物的文物',
  ruleTwoBulletFive: '氰化物或沉淀',
  ruleTwoBulletSix: '药品（包括处方药）/麻醉品/烟草',
  ruleTwoBulletSeven: '油',
  ruleTwoBulletEight: '灭火器',
  ruleTwoBulletNine: '生物样品',
}

const EN = {
  prohibitedItems: 'Prohibited Items',

  // rule 1
  ruleOne:
    '01. The following items (or any item similar to the description or content) are strictly prohibited by any of the courier company. Any shipment containing such items may be subjected to their order being cancelled without notice. Items that fall under these list are prohibited.',
  ruleOneBulletOne: 'Currency / money / cheque / coin / cash / gold / silver bullions',
  ruleOneBulletTwo: 'Counterfeit / pirated goods and materials',
  ruleOneBulletThree:
    'Driving licence / birth cert / identification card / passport / debit card / credit card',
  ruleOneBulletFour: 'Firecrackers',
  ruleOneBulletFive: 'Lottery tickets',
  ruleOneBulletSix: 'Weapons (real or fake)',

  // rule 2
  ruleTwo: '02. Special Request Required',
  ruleTwoBulletOne: 'Aerosol',
  ruleTwoBulletTwo: 'Alcohol',
  ruleTwoBulletThree: 'Animals of any form (alive or dead)',
  ruleTwoBulletFour: 'Animal antiquities',
  ruleTwoBulletFive: 'Cyanides or precipitates',
  ruleTwoBulletSix: 'Drugs (including prescription) / narcotics / tobacco',
  ruleTwoBulletSeven: 'Oil',
  ruleTwoBulletEight: 'Fire extinguisher',
  ruleTwoBulletNine: 'Biological samples',
}

export default {
  ZH_CN,
  EN,
}
