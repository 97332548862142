import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'

function PrivacyPolicy(props) {
  const { i18n } = props

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  return (
    <>
      <div className="container-fluid __policy" onContextMenu={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-12">
            <div className="__policy__title">
              <text>{i18n.privacyPolicy}</text>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleOne}</div>
            <div className="__policy__billboard__contents">
              {i18n.ruleOneDesc}
              <br />
              <br />
              <ul>
                <li>{i18n.ruleOneBulletOne}</li>
                <li>{i18n.ruleOneBulletTwo}</li>
                <li>{i18n.ruleOneBulletThree}</li>
                <li> {i18n.ruleOneBulletFour}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleTwo}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleTwoBulletOne}</li>
                <li>{i18n.ruleTwoBulletTwo}</li>
                <li>
                  {i18n.ruleTwoBulletThree}
                  <ul>
                    <li>{i18n.ruleTwoBulletThreeDetailOne}</li>
                    <li>{i18n.ruleTwoBulletThreeDetailTwo}</li>
                    <li>{i18n.ruleTwoBulletThreeDetailThree}</li>
                  </ul>
                </li>
                <li>
                  {i18n.ruleTwoBulletFour}
                  <ul>
                    <li>{i18n.ruleTwoBulletFourDetailOne}</li>
                    <li>{i18n.ruleTwoBulletFourDetailTwo}</li>
                    <li>{i18n.ruleTwoBulletFourDetailThree}</li>
                    <li>{i18n.ruleTwoBulletFourDetailFour}</li>
                  </ul>
                </li>
                <li>{i18n.ruleTwoBulletFive}</li>
                <li>{i18n.ruleTwoBulletSix}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleThree}</div>
            <div className="__policy__billboard__contents">{i18n.ruleThreeDesc}</div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleFour}</div>
            <div className="__policy__billboard__contents">{i18n.ruleFourDesc}</div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleFive}</div>
            <div className="__policy__billboard__contents">{i18n.ruleFiveDesc}</div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleSix}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleSixBulletOne}</li>
                <li>{i18n.ruleSixBulletTwo}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleSeven}</div>
            <div className="__policy__billboard__contents">
              {i18n.ruleSevenDescOne}
              <br />
              <br />
              <strong>{i18n.ruleSevenDescTwo}</strong>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleEight}</div>
            <div className="__policy__billboard__contents">{i18n.ruleEightDesc}</div>
          </div>
        </div>
      </div>
    </>
  )
}

PrivacyPolicy.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default PrivacyPolicy
