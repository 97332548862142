const ZH_CN = {
  termsConditions: '使用条款',

  // rule 1
  ruleOne: '1.0 客户义务',
  ruleOneBulletOne:
    'es2move将安排货物的取收和运送，并与信誉良好的承运人提供第三方经纪人服务。承运人有权出于给定的原因（例如包装不充分或货物尺寸过大）拒绝货物。',
  ruleOneBulletTwo: 'es2move 不会直接与货物联系，而是通过我们与其联系的任何一家承运人安排装运。',
  ruleOneBulletThree:
    '所有查询/通知都必须通过 es2move 进行指导，然后 es2move 将代表发送者与相关运营商联系。 如果直接与运营商联系，es2move 可能无法在以后帮助您进行查询。',

  // rule 2
  ruleTwo: '2.0 取收和运送',
  ruleTwoBulletOne:
    '我们的网站会根据客户的输入来获取所有预订资料，如果在分配的时间段内未进行托收，则客户有责任通过电子邮件通知 es2move 托收失败。然后，我们将努力重新安排另一个方便客户的集合。',
  ruleTwoBulletTwo: '取收和运送服务仅在工作日进行。',
  ruleTwoBulletThree:
    '取货时间（上午9点至下午6点），发货人或代表必须在取货地址。若取货失败，将不退款和不重新取货。',
  ruleTwoBulletFour:
    '请注意，所有的保证服务都在取货后开始实行。取货后将不退款。如果取货失败，客户有责任通知es2move重新安排下一个取货的时间。取货失败不予退款或折扣。',
  ruleTwoBulletFive:
    '如果收货人不在送货地点，则某些公司的司机可能会在门口留下电话卡，要求人工领取。否则，货物可能会退回仓库。',
  ruleTwoBulletSix: '客户有责任确保在第三方收货地址有人将货物交给快递员',
  ruleTwoBulletSeven: '跟踪和跟踪货物的详情信息仅在取收货物之后可用。',
  ruleTwoBulletEight: '收货人必须在收货时向司机要求在发件人副本上签名。',
  ruleTwoBulletNine: '对于按需服务，一旦成功下订单，将不允许取消订单，并且不会退款。',

  // rule 3
  ruleThree: '3.0 违禁物品和被限制的物品',
  ruleThreeBulletOne: '客户有责任阅读条款和条件或与我们联系以查看您的商品是否可以接受。',
  ruleThreeBulletTwoDescOne: '请注意，某些物品属于违禁物品，我们的服务无法发送。 根据此处的禁止物品清单 ',
  ruleThreeBulletTwoDescTwo:
    '。检查您的物品。 如果此清单中的以下任何一项由承运人收取，则您可能会被收取附加费并退还您的货物。 无法退款，赔偿要求。 我们有权决定全部或部分处置任何违禁物品，并保留向您收取由此产生的任何合理费用的权利。',
  ruleThreeBulletThree:
    'es2move 有权拦截，持有和调查任何怀疑包含违禁品或怀疑与欺诈案件有关的包裹的内容。 如有必要，将通知警方。',

  // rule 4
  ruleFour: '4.0 附加费用',
  ruleFourBulletOne:
    '输入商品的重量和尺寸，即表示您已预付了运费。如果货物较重，则将向发货人的帐户收取额外重量的额外费用。额外费用将按es2Move的费用支付。当出现重量或尺寸差异的情况时，es2Move的团队将通知发货人。',
  ruleFourBulletTwo:
    '如果发货人或代表发货人在设定的发货日期和时间（在发货单中设定）在发货地点不能抵达，则可能需要为重新交付任何货物支付额外费用。',
  ruleFourBulletThree:
    '对于要发送到郊区或从郊区取货的货物，可能会收取额外费用。这将在预订时在报价系统中进行计算。',
  ruleFourBulletFour: '自动化系统会为交货预定并收取费用。如果必须退货，则需收取退货费用。',
  ruleFourBulletFive: '您正在预付运费。如有其他海关费用，将另外转嫁。',
  ruleFourBulletSix:
    '我们可能会随您的发货提供文件。订购时会告知您。此文件必须随装运一起提供，否则，您的装运可能会延迟，承运人可能会直接向您收取更高的费用。',

  // rule 5
  ruleFive: '5.0 推迟/损坏/物品丢失',
  ruleFiveBulletOne: '我们的违禁物品中列出的任何物品，均免除延误，损坏和损失的索赔。',
  ruleFiveBulletTwo:
    '您的货物必须按照专业标准包装。由未按照专业标准包装的包裹引起的任何索赔将被拒绝。请检查我们的包装准则。',
  ruleFiveBulletThree:
    '如有损坏，必须保留所有包装以供检查。物品必须在其运送状态和运送地址可以检查。如果物品被移动/修改或不完好的包装，则索赔将被拒绝。',
  ruleFiveBulletFour:
    '通过我们的服务运送的任何物品都必须能够承受短时间的跌落，易碎物品不应通过我们的服务发送。任何包装完好无损，但会因摔落而损坏的物品，都将不接受。',
  ruleFiveBulletFive: '如果外部包装完好无损，而是由于内部包装不足所以不能保护产品的任何索赔都将无效。',
  ruleFiveBulletSix:
    '如果包装盒或包装在运送时明显受损，则收件人必须签名为“已损坏”，否则将被拒绝。发货者有责任将这些条款和条件告知接收者。',
  ruleFiveBulletSeven:
    '必须在每个快递公司指定的时间范围内报告所有丢失和损坏的货物。在指定时限之后报告的丢失和损坏的包裹将不符合赔偿要求。以下是为每个快递公司指定的时间范围。',

  // rule 6
  ruleSix: '6.0 责任与索赔',
  ruleSixBulletOne: '如果在预定取货日期的同一天取消了发货，则不会退款',
  ruleSixBulletTwo:
    '每个服务都附带有限数量的公交保险。 如果您需要全额承保，则您必须承保货物的全部价值，因为附加承保取代了包容性责任承保。',
  ruleSixBulletThree: '如果发生索赔，则需要物品的费用发票来证明货物的价值，并且必须为所有损坏索赔提供照片。',
  ruleSixBulletFour: '如果物品包装不充分，则索赔将被拒绝。 订购前请检查我们的准则。',
  ruleSixBulletFive: '如果商品标签不正确，则索赔将被拒绝。',
  ruleSixBulletSix: '请检查我们的“禁止物品”列表，以查看是否可以提出索赔。',
  ruleSixBulletSeven: '我们的“禁运物品”部分中列出的任何物品均免除我们的责任险。',
  ruleSixBulletEight: '对于赔偿要求，所有丢失和损坏的包裹必须在每个快递公司指定的时间范围内报告。',

  // rule 7
  ruleSeven: '7.0 帐户',
  ruleSevenBulletOne:
    '不得部分或全部转售 es2move 服务。 如果发生这种情况，该帐户应立即终止，且不退还任何潜在的信用余额。',
  ruleSevenBulletTwo:
    '下订单的人应对输入的信息负责。 es2move 对输入的错误信息概不负责，在这种情况下不会退款。',
}

const EN = {
  termsConditions: 'Terms and Conditions',

  // rule 1
  ruleOne: '1.0 Customer’s Obligations',
  ruleOneBulletOne:
    'es2move will arrange for collection and delivery of the goods, offering a third-party broker service with the reputable carriers. The carrier has the right to refuse a goods for given reason such as insufficient packaging or oversized of the goods.',
  ruleOneBulletTwo:
    'es2move does not come into direct contact with the goods but arranges the shipment through any of the carriers that we hold an account with.',
  ruleOneBulletThree:
    'All queries/claims must be directed through es2move, who will then contact the relevant carrier on the sender’s behalf. If the carrier is contacted directly, es2move may not be able to assist you with your query at a later date.',

  // rule 2
  ruleTwo: '2.0 Collection & Delivery',
  ruleTwoBulletOne:
    'Our website takes all booking data as per the customers input, if the collection does not happen within the time slot allocated, it is the customer’s responsibility to inform es2move via email of a failed collection. We will then endeavour to rearrange another collection which is convenient to the customer.',
  ruleTwoBulletTwo: 'Collections & deliveries are made on working days only.',
  ruleTwoBulletThree:
    'The consignor or representatives have to be at the collection address during collection hours (9am-6pm). No refunds and re-collection of shipments for unattended collection.',
  ruleTwoBulletFour:
    'Please note that all guaranteed services start once the goods is collected. No refunds will be given once the goods has been collected. If the collection fails, it is the customer’s responsibility to inform es2move to reschedule for the next collection. No refunds or discount for failed collections.',
  ruleTwoBulletFive:
    'If the consignee is not in during delivery, drivers from certain companies may leave a calling card at the door step requesting for manual collection. Otherwise, the consignment may to be returned to depot.',
  ruleTwoBulletSix:
    'It is the customer’s responsibility to ensure there is somebody at the third-party collection address to hand the goods to the courier.',
  ruleTwoBulletSeven: 'Track & Trade details are only available after the collection of consignments.',
  ruleTwoBulletEight:
    'The consignor must request for a signature on the Sender’s Copy from the drivers upon collection.',
  ruleTwoBulletNine:
    'For on demand services, once order has been successfully placed, cancellation is not allowed and no refund will be given.',

  // rule 3
  ruleThree: '3.0 Prohibited Items & Items with limited liability',
  ruleThreeBulletOne:
    'It is of the customer’s responsibility to either read the terms and conditions or to contact us to see if your goods is acceptable.',
  ruleThreeBulletTwoDescOne:
    'Please note that certain items are Prohibited Items and cannot be sent by our services. Check your item against the prohibited item list here ',
  ruleThreeBulletTwoDescTwo:
    '. If any one of the following items in this list is collected by carrier, you are then liable to be surcharged and your goods returned. NO refund will be made and compensation claims will be void. We have the right to dispose of any Prohibited Items, in whole or in part, as we decide and reserve the right to charge you for any reasonable costs we incur in doing so.',
  ruleThreeBulletThree:
    'es2move has the right to intercept, hold and investigate the contents of any parcel that is suspected to contain prohibited or is suspected to be related to a case of fraud. Police will be notified if necessary.',

  // rule 4
  ruleFour: '4.0 Additional Charges',
  ruleFourBulletOne:
    'By entering the weight and dimensions of your goods, you are pre-paying for the delivery charges. If the goods is heavier larger, extra charges for the additional weight will be charged to the consignor’s account. Additional charges will be at es2Move’s normal rate. es2Move’s support team will notify the consignor when cases on weight or dimension discrepancy happens.',
  ruleFourBulletTwo:
    'An extra charge for redelivery of any shipment may be done, if the shipper or a person representing the shipper was not available at the shipment venue during the period set for collecting the shipment (both which were set during the shipment order).',
  ruleFourBulletThree:
    'An extra charges may be made towards shipments that are meant to be sent to, or collected from, outskirt areas. This will be calculated in the quoting system at the time of booking.',
  ruleFourBulletFour:
    'The automated system books and charges for the delivery. If the goods has to be returned, then the return charge is chargeable.',
  ruleFourBulletFive:
    'You are pre-paying for the delivery charges. Any customs charges will be passed on in addition, should they arise.',
  ruleFourBulletSix:
    'We may supply documentation to accompany your shipments. You will be advised of this at the time of ordering. This documentation must go with the shipment, if not your shipment could be delayed and the carrier may charge you a higher premium directly.',

  // rule 5
  ruleFive: '5.0 Postponement / Damage / Loss of items',
  ruleFiveBulletOne:
    'Any item that is listed in our prohibited items, will be exempted from any claim against delay, damage and loss.',
  ruleFiveBulletTwo:
    'Your goods must be packed to a professional standard. Any claim resulting from a parcel that is not packaged to a professional standard will be rejected. Please check our Packaging Guidelines.',
  ruleFiveBulletThree:
    'In the event of damage all packaging must be kept for inspection. The item must be available for inspection in the state it was delivered, at the address it was delivered to. If the item is moved / repaired or if the packaging is not kept, the claim will be rejected.',
  ruleFiveBulletFour:
    'Any item travelling through our services must be able to withstand a short drop, fragile items should not be sent though our services. Any item that is damaged as a result of a fall, with the packaging intact will therefore be declined.',
  ruleFiveBulletFive:
    'If the outside packaging is intact, then any claim for damage to the goods will be invalidated as the internal packaging would not have been sufficient to protect the product.',
  ruleFiveBulletSix:
    'If the box or packaging is clearly damaged on delivery, it is of paramount importance the recipient must sign for as ‘DAMAGED’otherwise any claim for damage will be refused. It is the sender’s responsibility to inform the recipient of these terms and conditions.',
  ruleFiveBulletSeven:
    'All lost and damaged goods have to be reported within the time frame specified by each courier companies. Lost and damaged parcels reported after the specified time frame will not be eligible for the compensation claim. Below are the time frames specified for each courier companies.',

  // rule 6
  ruleSix: '6.0 Liability & Claims',
  ruleSixBulletOne:
    'NO refunds will be given for shipment if shipment cancellation is made on the same day as scheduled pick-up date.',
  ruleSixBulletTwo:
    'Each service comes with a limited amount of Transit cover. If you require coverage on the full amount then you must cover the full value of the shipment as the additional cover replaces the inclusive liability cover.',
  ruleSixBulletThree:
    'In the event of a claim, a cost invoice will be needed to prove the value of the goods and photos must be supplied for all damage claims.',
  ruleSixBulletFour:
    'If the item is not sufficiently packed, the claim will be rejected. Please check our guidelines before ordering.',
  ruleSixBulletFive: 'If the item is not correctly labelled, the claim will be rejected.',
  ruleSixBulletSix: 'Please check our Prohibited Items list to see if you are able to make a claim.',
  ruleSixBulletSeven:
    'Any item that is listed in our Prohibited Items section will be exempt from our liability cover.',
  ruleSixBulletEight:
    'For compensation claims, all lost and damaged parcels have to be reported within the time frame specified by each courier companies.',

  // rule 7
  ruleSeven: '7.0 Account',
  ruleSevenBulletOne:
    'No service of es2move shall be resold in part or in full. Should this happen, the account shall be immediately terminated, without refund of any potential credit balance.',
  ruleSevenBulletTwo:
    'The person placing the order is responsible for the information entered. es2move will not be held responsible for wrong information that is entered and no refunds will be given in this instance.',
}

export default {
  ZH_CN,
  EN,
}
