import header from './component/header'
import footer from './component/footer'

import Home from './pages/Home/Home'
import Driver from './pages/Driver/Driver'
import Refund from './pages/Refund/Refund'
import PrivacyPolicy from './pages/Policy/PrivacyPolicy'
import ProhibitedItems from './pages/Policy/ProhibitedItems'
import TermsAndCondition from './pages/Policy/TermsAndCondition'
import SearchOrder from './pages/SearchOrder/SearchOrder'
import QuickQuote from './pages/QuickQuote/QuickQuote'

import OrderStatus from './utils/orderStatus'

export const LanguageTable = {
  'zh-cn': {
    header: header.ZH_CN,
    footer: footer.ZH_CN,

    home: Home.ZH_CN,
    refund: Refund.ZH_CN,
    driver: Driver.ZH_CN,
    privacyPolicy: PrivacyPolicy.ZH_CN,
    prohibitedItems: ProhibitedItems.ZH_CN,
    termsAndCondition: TermsAndCondition.ZH_CN,
    searchOrder: SearchOrder.ZH_CN,
    quickquote: QuickQuote.ZH_CN,

    orderStatus: OrderStatus.ZH_CN,
  },
  en: {
    header: header.EN,
    footer: footer.EN,

    home: Home.EN,
    refund: Refund.EN,
    driver: Driver.EN,
    privacyPolicy: PrivacyPolicy.EN,
    prohibitedItems: ProhibitedItems.EN,
    termsAndCondition: TermsAndCondition.EN,
    searchOrder: SearchOrder.EN,
    quickquote: QuickQuote.EN,

    orderStatus: OrderStatus.EN,
  },
}
