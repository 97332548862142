import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'

function TermsAndConditions(props) {
  const { i18n } = props

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  return (
    <>
      <div className="container-fluid __policy" onContextMenu={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-12">
            <div className="__policy__title">
              <text>{i18n.termsConditions}</text>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleOne}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleOneBulletOne}</li>
                <li>{i18n.ruleOneBulletTwo}</li>
                <li>{i18n.ruleOneBulletThree}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleTwo}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleTwoBulletOne}</li>
                <li>{i18n.ruleTwoBulletTwo}</li>
                <li>{i18n.ruleTwoBulletThree}</li>
                <li>{i18n.ruleTwoBulletFour}</li>
                <li>{i18n.ruleTwoBulletFive}</li>
                <li>{i18n.ruleTwoBulletSix}</li>
                <li>{i18n.ruleTwoBulletSeven}</li>
                <li>{i18n.ruleTwoBulletEight}</li>
                <li>{i18n.ruleTwoBulletNine}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleThree}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleThreeBulletOne}</li>
                <li>
                  {i18n.ruleThreeBulletTwoDescOne}
                  <a href="https://www.es2move.com/prohibited-items">
                    https://www.es2move.com/prohibited-items
                  </a>
                  {i18n.ruleThreeBulletTwoDescTwo}
                </li>
                <li>{i18n.ruleThreeBulletThree}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleFour}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleFourBulletOne}</li>
                <li>{i18n.ruleFourBulletTwo}</li>
                <li>{i18n.ruleFourBulletThree}</li>
                <li>{i18n.ruleFourBulletFour}</li>
                <li>{i18n.ruleFourBulletFive}</li>
                <li>{i18n.ruleFourBulletSix}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleFive}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleFiveBulletOne}</li>
                <li>{i18n.ruleFiveBulletTwo}</li>
                <li>{i18n.ruleFiveBulletThree}</li>
                <li>{i18n.ruleFiveBulletFour}</li>
                <li>{i18n.ruleFiveBulletFive}</li>
                <li>{i18n.ruleFiveBulletSix}</li>
                <li>{i18n.ruleFiveBulletSeven}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleSix}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleSixBulletOne}</li>
                <li>{i18n.ruleSixBulletTwo}</li>
                <li>{i18n.ruleSixBulletThree}</li>
                <li>{i18n.ruleSixBulletFour}</li>
                <li>{i18n.ruleSixBulletFive}</li>
                <li>{i18n.ruleSixBulletSix}</li>
                <li>{i18n.ruleSixBulletSeven}</li>
                <li>{i18n.ruleSixBulletEight}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleSeven}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleSevenBulletOne}</li>
                <li>{i18n.ruleSevenBulletTwo}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TermsAndConditions.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default TermsAndConditions
