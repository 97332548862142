const ZH_CN = {
  // left description
  refundTitle: '与我们合作',
  driverDesc: '加入我们-马来西亚共享的物流平台，现在就与我们一起发展您的业务。 让我们一起革新运输行业。',

  // checker features
  noRegistFee: '无需注册费',
  expandBusiness: '通过我们的网络扩展您的业务',
  salesSupport: '销售和客户服务支持',
  foreignCustomers: '马来西亚和新加坡的客户',
  paidTimely: '及时获得付款并优化现金流',

  // right contact
  contactInfo: '您的联络资讯',
  name: '您的姓名',
  contactNumber: '您的联络电话',
  message: '讯息',
  submit: '提交',
  clear: '清除',
  moreinfo: '告诉我们更多相关的资讯',

  // snackbar
  success: '成功！您已经送出您的资讯。',
}

const EN = {
  // left description
  refundTitle: 'Partner With Us',
  driverDesc:
    'Join us – Malaysia’s shared logistics platform, and grow your business with us now.  Let’s revolutionize the transportation industry together. ',

  // checker features
  noRegistFee: 'No registration fee',
  expandBusiness: 'Expand your business with our network',
  salesSupport: 'Sales & Customer Service support',
  foreignCustomers: 'Customers across Malaysia & Singapore',
  paidTimely: 'Get paid in timely fashion and optimize your cashflow',

  // right contact
  contactInfo: 'Your Contact Info',
  name: 'Your Name',
  contactNumber: 'Your Contact Number',
  message: 'Message',
  submit: 'Submit',
  clear: 'Clear',
  moreinfo: 'Tell us more information about you.',

  // snackbar
  success: 'Success! You have already submitted your information.',
}

export default {
  ZH_CN,
  EN,
}
