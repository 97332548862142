import React from 'react'

const Translate = require('react-redux-i18n').Translate

// wording
export const title = <Translate value="searchOrder.title" />
export const copyDesc = <Translate value="searchOrder.copyDesc" />
export const copy = <Translate value="searchOrder.copy" />
export const copySuccess = <Translate value="searchOrder.copySuccess" />

export const number = <Translate value="searchOrder.number" />
export const status = <Translate value="searchOrder.status" />
export const created = <Translate value="searchOrder.created" />
export const progressing = <Translate value="searchOrder.progressing" />
export const inTransit = <Translate value="searchOrder.inTransit" />
export const arrived = <Translate value="searchOrder.arrived" />
export const cancelled = <Translate value="searchOrder.cancelled" />
export const contact = <Translate value="searchOrder.contact" />
export const backHome = <Translate value="searchOrder.backHome" />
export const searchTitle = <Translate value="searchOrder.searchTitle" />
export const searchDesc = <Translate value="searchOrder.searchDesc" />
export const invalidTitle = <Translate value="searchOrder.invalidTitle" />
export const invalidDesc = <Translate value="searchOrder.invalidDesc" />
export const inTransitToWarehouse =  <Translate value="searchOrder.inTransitToWarehouse" />
export const outOfDelivery =  <Translate value="searchOrder.outOfDelivery" />
export const delivered = <Translate value="searchOrder.delivered" />
