import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/Driver/Driver'
const i18n = require('./DriverContents')
function Driver(props) {
  const windowWidth = useSelector((state) => state.window.width)

  return <Layout i18n={i18n} windowWidth={windowWidth} />
}

Driver.propTypes = {
  windowWidth: PropTypes.string.isRequired,
}

export default Driver
