import React from 'react'

const Translate = require('react-redux-i18n').Translate

// wording
export const driverTitle = <Translate value="driver.refundTitle" />
export const driverDesc = <Translate value="driver.driverDesc" />

export const featureList = [
  { text: <Translate value="driver.noRegistFee" /> },
  { text: <Translate value="driver.expandBusiness" /> },
  { text: <Translate value="driver.salesSupport" /> },
  { text: <Translate value="driver.foreignCustomers" /> },
  { text: <Translate value="driver.paidTimely" /> },
]

export const contactInfo = <Translate value="driver.contactInfo" />
export const name = <Translate value="driver.name" />
export const contactNumber = <Translate value="driver.contactNumber" />
export const message = <Translate value="driver.message" />
export const submit = <Translate value="driver.submit" />
export const clear = <Translate value="driver.clear" />

export const success = <Translate value="driver.success" />
