const ORDER_CREATED = 0
const ORDER_PROGRESSING = 1
const ORDER_IN_TRANSIT = 2
const ORDER_ARRIVED = 4

export const ORDER_STATUS_CN = {
  [ORDER_CREATED]: '訂單已成立',
  [ORDER_PROGRESSING]: '訂單處理中',
  [ORDER_IN_TRANSIT]: '訂單運送中',
  [ORDER_ARRIVED]: '訂單已送達',
}

export const ORDER_STATUS_EN = {
  [ORDER_CREATED]: 'Created',
  [ORDER_PROGRESSING]: 'Progressing',
  [ORDER_IN_TRANSIT]: 'In Transit',
  [ORDER_ARRIVED]: 'Arrived',
}

export const ORDER_STATUS_LABEL = {
  created: ORDER_CREATED,
  progressing: ORDER_PROGRESSING,
  inTransit: ORDER_IN_TRANSIT,
  arrived: ORDER_ARRIVED,
}

export const ORDER_STATUS_TABLE = {
  1: ORDER_PROGRESSING,
  2: ORDER_PROGRESSING,
  3: ORDER_IN_TRANSIT,
  4: ORDER_IN_TRANSIT,
  5: ORDER_ARRIVED,
  6: ORDER_IN_TRANSIT,
  7: ORDER_ARRIVED,
  8: ORDER_ARRIVED,
}
