import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/QuickQuote/New'
import agent from '../../../agent'
function New(props) {
  const history = useHistory()
  const [options, setOptions] = useState([])
  const { windowWidth } = props
  const handleEvaluate = async (body) => {
    try{
      const result = await agent.Visitors.evaluate(body)
      console.log(result)
      if(result.data.success){
        return result.data.data.amount
      }
    }
    catch(err) {
      console.log(err)
    }
  }
  const getOptions = async () => {
    try{
      const result = await agent.Visitors.getItem()
      if(result.data.success){
        console.log(result.data.data)
        setOptions(result.data.data)
      }
    }
    catch(err){
      console.log(err)
    }
  }
  useEffect(() => {
    getOptions()
  },[])
  return (
    <Layout options={options} handleEvaluate={handleEvaluate}/>
  )
}

New.propTypes = {
  windowWidth: PropTypes.string.isRequired
}

export default New
