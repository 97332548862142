import { SET_WINDOW_WIDTH } from '../actions/actionTypes'

const initialState = { width: 'xs' }

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WINDOW_WIDTH:
      if (action.payload === 'xs') {
        const width = window.innerWidth
        let widthName
        if (width < 480) widthName = 'sm'
        else if (width < 768) widthName = 'md'
        else if (width < 1024) widthName = 'lg'
        else widthName = 'xl'
        return {
          ...state,
          width: widthName,
        }
      } else {
        return {
          ...state,
          width: action.payload,
        }
      }
    default:
      return state
  }
}
