import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'

import AddressIcon from '../../assets/icons/address.svg'
import EmailIcon from '../../assets/icons/email.svg'
import PhoneIcon from '../../assets/icons/phone.svg'
import CompLogo from '../../assets/images/es2moveLogoFooter.svg'

function Footer(props) {
  const { i18n } = props
  const history = useHistory()

  return (
    <footer>
      <div className="container-fluid">
        <div className="row footer container-es2move">
          <div className="col-12 col-lg-6 p-0">
            <div>
              <img src={CompLogo} alt="comp-logo" className="footer-copyright-logo" />
            </div>
            <div className="footer-copyright-text">
              <p>es2move © 2020. All Rights Reserved.</p>
            </div>
          </div>
          <div className="col-12 col-lg-3 p-0">
            <div className="footer-policies">
              <p className="footer-subtitle">{i18n.footerLeftHead}</p>
              <ul>
                <li>
                  <Link to="/refund" className="footer-policies-link">
                    {i18n.footerLeftOne}
                  </Link>
                </li>
                <li>
                  <Link to="/policy/privacy" className="footer-policies-link">
                    {i18n.footerLeftTwo}
                  </Link>
                </li>
                <li>
                  <Link to="/policy/term" className="footer-policies-link">
                    {i18n.footerLeftThree}
                  </Link>
                </li>
                <li className="last-link">
                  <Link to="/policy/prohibited" className="footer-policies-link">
                    {i18n.footerLeftFour}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-3 p-0">
            <div className="footer-contact-info">
              <p className="footer-subtitle">{i18n.footerRightHead}</p>
              <ul>
                <li>
                  <div>
                    <div className="footer-contact-option">
                      <img src={PhoneIcon} alt="contact-phone"></img>
                    </div>
                    <div className="footer-contact-option">
                      <p>{i18n.footerRightOne}</p>
                    </div>
                    <div className="footer-contact-detail">
                      <p>+60 167090669</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div>
                    <div className="footer-contact-option">
                      <img src={EmailIcon} alt="contact-email"></img>
                    </div>
                    <div className="footer-contact-option">
                      <p>{i18n.footerRightTwo}</p>
                    </div>
                    <div className="footer-contact-detail">
                      <p>enquiry@es2move.com</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div>
                    <div className="footer-contact-option">
                      <img src={AddressIcon} alt="contact-address"></img>
                    </div>
                    <div className="footer-contact-option">
                      <p>{i18n.footerRightThree}</p>
                    </div>
                    <div className="footer-contact-detail">
                      <p>
                        33B, Jalan Setia Tropika 1/17, Taman Setia Tropika, 81200, Johor Bahru, Johor,
                        Malaysia.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Footer
