import React from 'react'

import QualityMatching from '../../../assets/icons/servicesQualityMatching.svg'
import AutomatedCredit from '../../../assets/icons/servicesAutomatedCredit.svg'
import CompleteControl from '../../../assets/icons/servicesCompleteControl.svg'
import CrossBorder from '../../../assets/icons/servicesCrossBorder.svg'
import DeliveryDetails from '../../../assets/icons/servicesDeliveryDetails.svg'
import TraceOrder from '../../../assets/icons/servicesTraceOrder.svg'
import DailySupplies from '../../../assets/images/productsDailySupplies.svg'
import Food from '../../../assets/images/productsFood.svg'
import LargeGoods from '../../../assets/images/productsLargeGoods.svg'
import ElectricEquipment from '../../../assets/images/productsElectricEquipment.svg'

const Translate = require('react-redux-i18n').Translate

// wording
export const mainTitle = <Translate value="home.mainTitle" />
export const mainSubtitleOne = <Translate value="home.mainSubtitleOne" />
export const mainSubtitleTwo = <Translate value="home.mainSubtitleTwo" />
export const priceTitle = <Translate value="home.priceTitle" />
export const priceSubtitle = <Translate value="home.priceSubtitle" />
export const products = [
  {
    title: <Translate value="home.orderTypeDailySupplies" />,
    desc: <Translate value="home.orderTypeDailySuppliesDesc" />,
    img: <img src={DailySupplies} alt="daily-supplies.img" />,
    priceStandard: '13',
    priceExpress: '16.9',
    category: 'Daily Supplies',
  },
  {
    title: <Translate value="home.orderTypeFood" />,
    desc: <Translate value="home.orderTypeFoodDesc" />,
    img: <img src={Food} alt="food.img" />,
    priceStandard: '13',
    priceExpress: '16.9',
    category: 'Food',
  },
  {
    title: <Translate value="home.orderTypeLargeGoods" />,
    desc: <Translate value="home.orderTypeLargeGoodsDesc" />,
    img: <img src={LargeGoods} alt="large-goods.img" />,
    priceStandard: '65',
    priceExpress: '58.5',
    category: 'Large Goods',
  },
  {
    title: <Translate value="home.orderTypeEE" />,
    desc: <Translate value="home.orderTypeEEDesc" />,
    img: <img src={ElectricEquipment} alt="electric-equipment.img" />,
    priceStandard: '13',
    priceExpress: '44.2',
    category: 'Electric Equipment',
  },
]
export const priceFlagTitleOne = <Translate value="home.priceFlagTitleOne" />
export const priceFlagTitleTwo = <Translate value="home.priceFlagTitleTwo" />
export const priceFlagUpTo = <Translate value="home.priceFlagUpTo" />
export const priceFlagPercent = '15'
export const servicesTitle = <Translate value="home.servicesTitle" />
export const servicesSubtitle = <Translate value="home.servicesSubtitle" />
export const services = [
  {
    img: <img src={QualityMatching} alt="quality-matching.img" />,
    title: <Translate value="home.qualityMatching" />,
    desc: <Translate value="home.qualityMatchingDesc" />,
  },
  {
    img: <img src={AutomatedCredit} alt="automated-credit.img" />,
    title: <Translate value="home.automatedCredit" />,
    desc: <Translate value="home.automatedCreditDesc" />,
  },
  {
    img: <img src={CompleteControl} alt="complete-control.img" />,
    title: <Translate value="home.completeControl" />,
    desc: <Translate value="home.completeControlDesc" />,
  },
  {
    img: <img src={CrossBorder} alt="cross-border.img" />,
    title: <Translate value="home.crossBorder" />,
    desc: <Translate value="home.crossBorderDesc" />,
  },
  {
    img: <img src={DeliveryDetails} alt="delivery-details.img" />,
    title: <Translate value="home.deliveryDetails" />,
    desc: <Translate value="home.deliveryDetailsDesc" />,
  },
  {
    img: <img src={TraceOrder} alt="trace-order.img" />,
    title: <Translate value="home.tradeOrder" />,
    desc: <Translate value="home.tradeOrderDesc" />,
  },
]
export const priceFootnoteOne = <Translate value="home.priceFootnoteOne" />
export const priceFootnoteTwo = <Translate value="home.priceFootnoteTwo" />
export const priceFootnoteContact = <Translate value="home.priceFootnoteContact" />

export const returnTitle = <Translate value="home.returnRefund" />
export const returnSubtitle = <Translate value="home.returnRefundSubtitle" />
export const returnDescOne = <Translate value="home.returnDescOne" />
export const returnDescTwo = <Translate value="home.returnDescTwo" />
export const discountDesc = <Translate value="home.onlineOrder" />
export const contactAgent = <Translate value="home.contactAgent" />
export const newOrder = <Translate value="home.newOrder" />
export const trackYourOrder = <Translate value="home.trackYourOrder" />
export const from = <Translate value="home.from" />
export const orderQuickQuote = <Translate value="home.orderQuickQuote" />
export const orderStandard = <Translate value="home.orderStandard" />
export const orderExpress = <Translate value="home.orderExpress" />

export const driverPartner = <Translate value="home.driverPartner" />
export const searchNo = <Translate value="home.searchNo" />

export const footerLeftHead = <Translate value="home.footerLeftHead" />
export const footerLeftOne = <Translate value="home.footerLeftOne" />
export const footerLeftTwo = <Translate value="home.footerLeftTwo" />
export const footerLeftThree = <Translate value="home.footerLeftThree" />
export const footerLeftFour = <Translate value="home.footerLeftFour" />

export const footerRightHead = <Translate value="home.footerRightHead" />
export const footerRightOne = <Translate value="home.footerRightOne" />
export const footerRightTwo = <Translate value="home.footerRightTwo" />
export const footerRightThree = <Translate value="home.footerRightThree" />

// search order status
export const orderNumber = <Translate value="orderStatus.orderNumber" />
export const orderStatus = <Translate value="orderStatus.orderStatus" />
export const orderCreated = <Translate value="orderStatus.orderCreated" />
export const orderProgressing = <Translate value="orderStatus.orderProgressing" />
export const orderTransit = <Translate value="orderStatus.orderTransit" />
export const orderArrived = <Translate value="orderStatus.orderArrived" />
export const orderCancelled = <Translate value="orderStatus.orderCancelled" />
export const searchOrderTitle = <Translate value="orderStatus.searchOrderTitle" />
export const searchOrderDesc = <Translate value="orderStatus.searchOrderDesc" />
