import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SET_SEARCH_BURGER, SET_BURGER_CONTENTS } from '../../actions/actionTypes'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { chgLanguage } from '../../constants/i18n'
import CompLogoHeader from '../../assets/images/es2moveLogoHeader.svg'
import CompLogoHeaderMobile from '../../assets/images/es2moveLogoHeaderMobile.svg'
import QuickQuoteMobile from '../../assets/images/quickQuoteMobile.svg'
import SearchGlobe from '../../assets/icons/language.svg'
import SearchGlobeMobile from '../../assets/icons/languageMobile.svg'
import SearchLaptop from '../../assets/icons/searchLaptop.svg'
import MenuBurger from '../../assets/icons/menuBurger.svg'
import MenuCross from '../../assets/icons/menuCross.svg'
import SearchMobile from '../../assets/icons/searchMobile.svg'
import SearchMobileBlue from '../../assets/icons/searchMobileBlue.svg'
import SearchbarHiddenIcon from '../../assets/icons/searchbarHiddenIcon.svg'
import OpenSelectionIcon from '../../assets/icons/openSelectionIcon.svg'

const classNames = require('classnames')

const langDict = {
  English: 'en',
  简体中文: 'zh-cn',
}

const langDictRev = {
  en: 'English',
  'zh-cn': '简体中文',
}

function Header(props) {
  const { i18n } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { showingMobileBurgerContents, showingMobileSearchbar } = useSelector((state) => state.search)
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [searchOrderNumber, setSearchOrderNumber] = useState('')
  const [showingMobileBurgerLanguage, setShowingMobileBurgerLanguage] = useState(false)
  const [isHeaderAtTop, setIsHeaderAtTop] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`)
  const SearchGlobeIcon = (
    <div className="header-language-current">
      <img src={SearchGlobe} alt="search-globe" />
      <p>{currentLanguage}</p>
    </div>
  )

  const SearchBarPlaceholder = langDict[currentLanguage] === 'zh-cn' ? '搜寻订单编号' : 'Search order No.'

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (currentScrollY >= 200) {
        setIsHeaderAtTop(false)
      } else {
        setIsHeaderAtTop(true)
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [isHeaderAtTop])

  const handleCloseSearchbar = () => {
    dispatch({
      type: SET_SEARCH_BURGER,
      showingMobileSearchbar: false,
    })
  }
  const handleCloseMenuburger = () => {
    dispatch({
      type: SET_BURGER_CONTENTS,
      showingMobileSearchbar: false,
    })
  }

  const handleSearchbarClick = () => {
    dispatch({
      type: SET_SEARCH_BURGER,
      showingMobileSearchbar: !showingMobileSearchbar,
    })
    if (showingMobileBurgerContents) {
      dispatch({
        type: SET_BURGER_CONTENTS,
        showingMobileBurgerContents: !showingMobileBurgerContents,
      })
    }
  }

  const handleMenuburgerClick = () => {
    dispatch({
      type: SET_BURGER_CONTENTS,
      showingMobileBurgerContents: !showingMobileBurgerContents,
    })
    if (showingMobileSearchbar) {
      dispatch({
        type: SET_SEARCH_BURGER,
        showingMobileSearchbar: !showingMobileSearchbar,
      })
    }
  }

  const handleBurgerLanguageClick = () => {
    setShowingMobileBurgerLanguage(!showingMobileBurgerLanguage)
  }

  const handleLanguageChange = (value) => {
    setCurrentLanguage(langDictRev[value])
    window.localStorage.setItem('lang', value)
    chgLanguage(value)
  }

  const handleLanguageChangeMobile = (value) => {
    setCurrentLanguage(langDictRev[value])
    window.localStorage.setItem('lang', value)
    setShowingMobileBurgerLanguage(false)
    chgLanguage(value)
  }

  const handleOrderSearch = (e) => {
    e.preventDefault()
    window.localStorage.removeItem('isbot')
    if (searchOrderNumber !== '') {
      history.push(`/search?id=${searchOrderNumber}`)
      window.location.reload()
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem('lang') === null) {
      let language = (navigator.language || navigator.browserLanguage).toLowerCase()
      language = language !== 'zh-cn' ? 'en' : language
      window.localStorage.setItem('lang', language)
      setCurrentLanguage(langDictRev[language])
    } else {
      setCurrentLanguage(langDictRev[window.localStorage.getItem('lang')])
    }
  }, [])

  return (
    <>
      <div className="container-fluid">
        <Navbar bg="white" className={classNames('header-navbar', isHeaderAtTop ? '' : 'active')} fixed="top">
          {/* LAPTOP navbar */}
          <div className="row header-navbar-laptop container-es2move">
            <div className="col-5 header-navbar-logo p-0">
              <Link to="/" className="header-logo">
                <img src={CompLogoHeader} alt="comp-logo" />
              </Link>
              <Link to="/driver" className="header-partner-link">
                <span>{i18n.driverPartner}</span>
              </Link>
            </div>
            <div className="col-7 header-navbar-functions container-fluid d-flex">
              <div className="row header-navbar-collapse d-flex">
                <div className="col header-language">
                  <Nav className="header-language-option">
                    <NavDropdown title={SearchGlobeIcon} id="basic-nav-dropdown">
                      <Link className="header-language-item" onClick={() => handleLanguageChange('en')}>
                        <text>English</text>
                      </Link>
                      <Link className="header-language-item" onClick={() => handleLanguageChange('zh-cn')}>
                        <text>简体中文</text>
                      </Link>
                    </NavDropdown>
                  </Nav>
                </div>

                <div className="header-divider"></div>
                <div className="col-2 col-lg-5 header-searchbar">
                  <form
                    className={classNames('header-searchbar-input', 'd-flex', isSearching ? 'active' : '')}
                    onSubmit={(e) => handleOrderSearch(e)}
                  >
                    <input
                      type="text"
                      value={searchOrderNumber}
                      placeholder={SearchBarPlaceholder}
                      onClick={() => setIsSearching(true)}
                      onBlur={() => setIsSearching(false)}
                      onChange={(e) => setSearchOrderNumber(e.target.value)}
                    />
                    <img src={SearchLaptop} alt="searchbar-icon" onClick={handleOrderSearch} />
                  </form>
                </div>

                <div className="header-divider"></div>
                <div className="col header-neworder">
                  <div className="header-neworder-button">
                    <a
                      href="https://wa.me/60167758895"
                      className="btn btn-sm btn-primary header-order-button button"
                      target="_blank"
                    >
                      {i18n.newOrder}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MOBILE navbar */}
          <div className="row header-navbar-mobile">
            <div className="header-navbar-logo">
              <Link
                to="/"
                className="header-logo"
                onClick={() => {
                  setIsSearching(false)
                  handleCloseSearchbar()
                  handleCloseMenuburger()
                }}
              >
                <img src={CompLogoHeaderMobile} alt="comp-logo" />
              </Link>
            </div>
            <div className="row header-navbar-functions">
              <Navbar.Brand className="header-mobile-icons">
                <a onClick={() => handleSearchbarClick()}>
                  {showingMobileSearchbar ? (
                    <img src={SearchMobileBlue} alt="search-mobile-icon-blue" />
                  ) : (
                    <img src={SearchMobile} alt="search-mobile-icon" />
                  )}
                </a>
              </Navbar.Brand>
              <Navbar.Brand className="header-mobile-icons">
                <a onClick={() => handleMenuburgerClick()}>
                  {showingMobileBurgerContents ? (
                    <img src={MenuCross} alt="menu-burger-cross" />
                  ) : (
                    <img src={MenuBurger} alt="menu-burger" />
                  )}
                </a>
              </Navbar.Brand>
            </div>

            {showingMobileSearchbar && (
              <div className="header-searchbar">
                <form
                  className={classNames('header-searchbar-input', 'd-flex', isSearching ? 'active' : '')}
                  onSubmit={(e) => {
                    setIsSearching(false)
                    handleOrderSearch(e)
                    handleCloseSearchbar()
                  }}
                >
                  <input
                    type="text"
                    value={searchOrderNumber}
                    onClick={() => setIsSearching(true)}
                    onBlur={() => setIsSearching(false)}
                    onChange={(e) => setSearchOrderNumber(e.target.value)}
                    autoFocus="true"
                  ></input>
                  <img
                    src={SearchbarHiddenIcon}
                    alt="searchbar-icon-hidden"
                    onClick={(e) => {
                      setIsSearching(false)
                      handleCloseSearchbar()
                      handleOrderSearch(e)
                    }}
                  />
                </form>
                <div className="header-searchbar-quickquote d-flex">
                  <img src={QuickQuoteMobile} alt="quick-quote-img" className="col-12" />
                  <text className="quickquote-title col-12">{i18n.searchOrderTitle}</text>
                  <text className="quickquote-desc col-12">{i18n.searchOrderDesc}</text>
                </div>
              </div>
            )}

            {showingMobileBurgerContents && (
              <div className="header-burger">
                <div className="header-burger-contents">
                  <div className="header-burger-partner-link">
                    <Link to="/driver" onClick={handleCloseMenuburger}>
                      <span>{i18n.driverPartner}</span>
                    </Link>
                  </div>
                  <div className="header-burger-language">
                    <div
                      className="header-burger-language-current"
                      onClick={() => handleBurgerLanguageClick()}
                    >
                      <img src={SearchGlobeMobile} alt="search-globe" />
                      <p>{currentLanguage}</p>
                      <img src={OpenSelectionIcon} alt="open-selection" />
                    </div>
                    {showingMobileBurgerLanguage && (
                      <div className="header-burger-language-option">
                        <Link
                          className="header-language-item p-0"
                          onClick={() => handleLanguageChangeMobile('en')}
                        >
                          <p>English</p>
                        </Link>

                        <Link
                          className="header-language-item p-0"
                          onClick={() => handleLanguageChangeMobile('zh-cn')}
                        >
                          <p>简体中文</p>
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className="header-burger-neworder">
                    <a
                      href="https://wa.me/60167758895"
                      className="btn btn-sm btn-primary  header-burger-neworder-button"
                      target="_blank"
                    >
                      {i18n.newOrder}
                    </a>
                  </div>
                </div>
                <div className="header-burger-dark-bg" onClick={handleCloseMenuburger}></div>
              </div>
            )}
          </div>
        </Navbar>
      </div>
    </>
  )
}

Header.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Header
