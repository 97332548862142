const ZH_CN = {
  orderNumber: '订单编号',
  orderStatus: '订单状态',
  orderCreated: '已建立',
  orderProgressing: '处理中',
  orderTransit: '运送中',
  orderArrived: '已配达',
  orderCancelled: '已取消',

  searchOrderTitle: '搜寻订单状态',
  searchOrderDesc: '请输入订单编号来搜寻您的订单状态',

}

const EN = {
  orderNumber: 'Order No. P00000001',
  orderStatus: 'Order Status',
  orderCreated: 'Created',
  orderProgressing: 'Progressing',
  orderTransit: 'In Transit',
  orderArrived: 'Arrived',
  orderCancelled: 'Cancelled',

  searchOrderTitle: 'Search Order Status',
  searchOrderDesc: 'Please enter the order No. to check your order status.',
}

export default {
  ZH_CN,
  EN
}
