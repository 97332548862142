import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/SearchOrder/SearchOrder'
import agent from '../../../agent'
const i18n = require('./SearchOrderContents')

function SearchOrder(props) {
  const history = useHistory()
  const { windowWidth } = props

  const getOrderStatus = async (params) => {
    try {
      const result = await agent.Visitors.getOrderStatus(params)
      if (result.data.success) {
        return result.data.data
      }
    } catch (err) {
      console.log(err)
    }
  }

  return <Layout windowWidth={windowWidth} getOrderStatus={getOrderStatus} i18n={i18n} />
}

SearchOrder.propTypes = {
  windowWidth: PropTypes.string.isRequired,
}

export default SearchOrder
