import React, { useEffect } from 'react'
import Proptypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { SET_WINDOW_WIDTH } from './actions/actionTypes'
import Full from './containers/Full'

import { setLanguage } from './constants/i18n'
import { useWindowSize } from '../src/lib/reactHook'

function App(props) {
  const width = useWindowSize()
  const dispatch = useDispatch()

  useEffect(() => {
    setLanguage()
  }, [])

  useEffect(() => {
    dispatch({
      type: SET_WINDOW_WIDTH,
      payload: width,
    })
  }, [width])

  return (
    <Switch>
      <Route path="/" name="Full" component={Full} />
    </Switch>
  )
}

App.protoTypes = {
  setWindowWidth: Proptypes.func.isRequired,
}

export default App
