import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'

import { RECAPTCHA_KEY } from '../../constants/config'

const classNames = require('classnames')

function Recaptcha(props) {
  const { classes, ref, onChange } = props
  return (
    <ReCAPTCHA
      // input: string
      className={classNames(classes.split(','))}
      ref={ref}
      hl={window.localStorage.getItem('lang')}
      sitekey={RECAPTCHA_KEY}
      onChange={onChange}
    />
  )
}

Recaptcha.propTypes = {
  classes: PropTypes.string.isRequired,
  ref: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Recaptcha
