const ZH_CN = {
  // first section
  mainTitle: '简单、省时的运送解决方案',
  mainSubtitleOne: 'Es2move 为您提供马来西亚最好的物流解决方案。',
  mainSubtitleTwo: '快速预订，并一键取得详细的送货资讯。',
  newOrder: '联系客服',
  trackYourOrder: '追踪您的订单',

  // second section
  priceFlagTitleOne: '线上下单',
  priceFlagTitleTwo: '折扣',
  priceFlagUpTo: '最高',
  priceTitle: 'Es2move 将提供最低报价以满足您的需求',
  priceSubtitle: '安排从 Johor 到 Perak 的货物运送。',
  orderStandard: '一般',
  orderExpress: '急件',
  orderTypeDailySupplies: '民生用品',
  orderTypeDailySuppliesDesc: '口罩、肥料、贴纸、文件、护肤产品',
  orderTypeFood: '食物',
  orderTypeFoodDesc: '水果篮、点心、糖果',
  orderTypeLargeGoods: '大型货物',
  orderTypeLargeGoodsDesc: '招牌、电视、冰箱',
  orderTypeEE: '电器产品',
  orderTypeEEDesc: '吹風機、剃刀、蒸汽爐、榨汁機、打印機',
  orderPriceFrom: '最低',
  orderQuickQuote: '快速估价',
  priceFootnoteOne: '如果您的货物不属于任何上述的选项，请',
  priceFootnoteTwo: '，我们专业的客服会给您最好的协助及报价。',
  priceFootnoteContact: '联系客服',

  // third section
  servicesTitle: '我們提供的服务与功能',
  servicesSubtitle: '明智解决方案，推动您的业务。',
  qualityMatching: '品质匹配',
  qualityMatchingDesc:
    '全天候24小时即时得到最恰当的货物容量安排以满足所有您的业务需求。 您的运送订单将由专业人员为你完成。',
  automatedCredit: '自动贷记',
  automatedCreditDesc: '使用信用卡支付，付款支出自动化。您也能够在预定之前就知道价格，价格呈现透明化。',
  completeControl: '完全控制',
  completeControlDesc:
    '享受前所未有的透明性服务，节省您的时间，以推动您的业务。把您的预订过程从头到尾数字化。',
  crossBorder: '跨境',
  crossBorderDesc: '我们的专业运输人员拥有齐全的配备，以确保安全、便捷、和提供及时的运输服务。',
  deliveryDetails: '送货详情',
  deliveryDetailsDesc: '与客户或需要知道的人共享交货详细信息。',
  tradeOrder: '交易订单',
  tradeOrderDesc: '使用我们的移动应用程序和桌面应用程序实时跟踪您的订单。',

  // fourth section
  returnRefund: '退款与退货政策',
  returnRefundSubtitle: '担心损坏?所有送货服务均包含保险。(需符合条件)',
  returnDescOne:
    '感谢您在 S2trading Sdn.Bhd. 运营的 es2move.com 购买我们的送货服务。我们网站上的所有交付订单都有提供全额退款保证。 如果您对我们的送货服务不满意，则可以申请退款。 您有资格在购买后的 14 个工作日内要求全额退款。',
  returnDescTwo: '14 天后将不符合条规，不能够要求退款。 如果您还有其他疑问或想要退款，请随时与我们联系。',

  // fifth section
  onlineOrder: '立刻在线上下单！',

  // header
  driverPartner: '成为司机',
  searchNo: '搜寻订单编号',

  // footer
  footerLeftHead: '公司政策',
  footerLeftOne: '退货与退款',
  footerLeftTwo: '隐私权政策',
  footerLeftThree: '使用条款',
  footerLeftFour: '禁止物品',
  footerRightHead: '联络资讯',
  footerRightOne: '电话',
  footerRightTwo: '电子邮件',
  footerRightThree: '地址',

  // others
  from: '从',
}

const EN = {
  // first section
  mainTitle: 'Simple, Time Saving Delivery Solution',
  mainSubtitleOne: 'Es2move brings you the best delivery and logistics solutions in Malaysia. ',
  mainSubtitleTwo: 'Make quick booking and get clear update of all your deliveries under one dashboard.',
  newOrder: 'Contact Us',
  trackYourOrder: 'Track Your Order',
  // second section
  priceFlagTitleOne: 'Online Order',
  priceFlagTitleTwo: 'Discount',
  priceFlagUpTo: 'up to',
  priceTitle: 'Es2move will Meet Your Requirements at the Lowest Price',
  priceSubtitle: 'Scheduled goods delivery from Johor to Perak.',
  orderStandard: 'Standard',
  orderExpress: 'Express',
  orderTypeDailySupplies: 'Daily Supplies',
  orderTypeDailySuppliesDesc: 'Masks, fertilizers, stickers, documents, skin care products',
  orderTypeFood: 'Food',
  orderTypeFoodDesc: 'Fruit basket, snacks, candy',
  orderTypeLargeGoods: 'Large Goods',
  orderTypeLargeGoodsDesc: 'Signboard, TV, refrigerator',
  orderTypeEE: 'Electric Equipment',
  orderTypeEEDesc: 'Hairdryer, shavers, steam cooker, juice machine, printer',
  orderPriceFrom: 'from',
  orderQuickQuote: 'Quick Quote',
  priceFootnoteOne:
    'If you want to deliver your valued goods which are not included at the mentioned categories above, please do not hesitate to ',
  priceFootnoteTwo: ' and find out more.',
  priceFootnoteContact: 'contact us',

  // third section
  servicesTitle: 'Services and Features',
  servicesSubtitle: 'Smart solutions to power your business.',
  qualityMatching: 'Quality Matching',
  qualityMatchingDesc:
    'Direct access to instant capacity to meet all your business needs in 24/7. Peace of mind knowing your bookings are accomplished by highly skilled professionals.',
  automatedCredit: 'Automated Credit',
  automatedCreditDesc:
    'Automate your logistics spending with hassle free credit payment, no more price haggling and ambiguity. Always know your cost before you book.',
  completeControl: 'Complete Control',
  completeControlDesc:
    'Enjoy unparalleled visibility, saving you time to focus on powering your business. Digitize your booking processes end to end.',
  crossBorder: 'Cross Border',
  crossBorderDesc:
    'Our professional transportation operators are equipped to ensure safe, hassle-free, and timely transportation services.',
  deliveryDetails: 'Delivery Details',
  deliveryDetailsDesc: 'Share the delivery details with the customer or who need to know.',
  tradeOrder: 'Trade Order',
  tradeOrderDesc: 'Trade your order in real time with our user- friendly mobile and desktop application.',

  // fourth section
  returnRefund: 'Return and Refund Policy',
  returnRefundSubtitle: 'Worry damaging? All delivery service is included insurance. (T&C applied only)',
  returnDescOne:
    'Thanks for purchasing our delivery services at es2move.com operated by S2trading Sdn. Bhd. We offer a full money-back guarantee for all deliver orders made on our website. If you are not satisfied with the service that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 working days of your purchase.',
  returnDescTwo:
    'After the 14-day period, you will no longer be eligible and won’t be able to receive a refund. If you have any additional questions or would like to request a refund, feel free to contact us.',

  // fifth section
  onlineOrder: 'Online Order Discount. Right Away!',

  // header
  driverPartner: 'Driver Partner',
  searchNo: 'Search order No.',

  // footer
  footerLeftHead: 'Company Policies',
  footerLeftOne: 'Return & Refund',
  footerLeftTwo: 'Privacy Policy',
  footerLeftThree: 'Terms and Conditions',
  footerLeftFour: 'Prohibited Items',
  footerRightHead: 'Contact Info',
  footerRightOne: 'Phone',
  footerRightTwo: 'Email',
  footerRightThree: 'Address',

  // others
  from: 'from',
}

export default {
  ZH_CN,
  EN,
}
