import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import reducer from './reducer'

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(thunk)
  } else {
    return applyMiddleware(thunk, createLogger())
  }
}

export const store = createStore(reducer, getMiddleware())
