import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import FeatureSuccess from '../../assets/icons/featureSuccess.svg'
import FeatureSuccessMobile from '../../assets/icons/featureSuccessMobile.svg'
const I18n = require('react-redux-i18n').I18n

function Driver(props) {
  const { i18n, windowWidth } = props
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)
  const [snackbarVertical, setSnackbarVertical] = useState('')
  const [name, setName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [msg, setMsg] = useState('')

  const autoHeight = () => {
    var element = document.getElementById('input-message')
    element.style.height = 'auto'
    element.style.height = element.scrollHeight + 'px'
  }

  const handleDriverFormClick = () => {
    if (name !== '' && contactNumber !== '' && msg !== '') {
      setOpenSuccessSnackbar(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccessSnackbar(false)
  }

  const handleClear = () => {
    setName('')
    setContactNumber('')
    setMsg('')
  }

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const sentReqToService = () => {
    const lang = window.localStorage.getItem('lang')
    if (lang === 'en') {
      const msgSend = `I have an interest in driver partner, my name is ${name}, and my tel: ${contactNumber}. ${msg}`
      window.open(`https://wa.me/60167758895?text=${encodeURIComponent(msgSend)}`)
    } else if (lang === 'zh-cn') {
      const msgSend = `我有興趣成為司機，我的名字是 ${name}，電話：${contactNumber}， "${msg}"`
      window.open(`https://wa.me/60167758895?text=${encodeURIComponent(msgSend)}`)
    }
  }

  useEffect(() => {
    if (openSuccessSnackbar) {
      window.setTimeout(sentReqToService, 1000)
    }
  }, [openSuccessSnackbar])

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  useEffect(() => {
    if (windowWidth === 'xl' || windowWidth === 'lg') {
      setSnackbarVertical('bottom')
    } else {
      setSnackbarVertical('top')
    }
  }, [windowWidth])

  return (
    <>
      <div className="container-fluid __driver container-es2move">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="__driver__title">
              <text>{i18n.driverTitle}</text>
            </div>
            <div className="__driver__desc">
              <text>{i18n.driverDesc}</text>
            </div>
            <div className="__driver__features">
              {i18n.featureList.map((item, ind) => (
                <div className="__driver__feature d-flex">
                  <div className="feature-image">
                    {windowWidth === 'xl' || windowWidth === 'lg' ? (
                      <img src={FeatureSuccess} alt="feature-success.img" />
                    ) : (
                      <img src={FeatureSuccessMobile} alt="feature-success.img" />
                    )}
                  </div>
                  <div className="feature-text">
                    <text>{item.text}</text>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="__driver__form col-12 col-lg-6">
            <div className="__driver__form__input__group" id="input-space">
              <div className="input-title">{i18n.contactInfo}</div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="input-name es2move-body1-regular">{i18n.name}</div>
                  <input
                    value={name}
                    className="input-blank es2move-body1-regular"
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="input-contact es2move-body1-regular">{i18n.contactNumber}</div>
                  <input
                    type="tel"
                    value={contactNumber}
                    className="input-blank es2move-body1-regular"
                    onChange={(e) => setContactNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="input-message es2move-body1-regular">{i18n.message}</div>
              <textarea
                id="input-message"
                value={msg}
                className="input-blank es2move-body1-regular"
                rows="1"
                placeholder={I18n.t('driver.moreinfo')}
                style={{ resize: 'none' }}
                onKeyUp={() => autoHeight()}
                onChange={(e) => setMsg(e.target.value)}
              ></textarea>

              <div className="btns">
                <button className="btn col-none col-lg-4 p-0 m-0" disabled></button>
                <button className="btn btn-submit col-12 col-md-4" onClick={handleDriverFormClick}>
                  {i18n.submit}
                </button>
                <button className="btn btn-clear col-12 col-md-4" onClick={handleClear}>
                  {i18n.clear}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="driver-snack-alert">
        <Snackbar
          anchorOrigin={{ vertical: snackbarVertical, horizontal: 'center' }}
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" className="es2move-body1-medium">
            {i18n.success}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

Driver.propTypes = {
  i18n: PropTypes.object.isRequired,
  windowWidth: PropTypes.string.isRequired,
}

export default Driver
