const ZH_CN = {
  privacyPolicy: '隐私权政策',
  // rule 1
  ruleOne: '01. 一般',
  ruleOneDesc: '以下列表是运输的条款和条件：',
  ruleOneBulletOne:
    '个人数据是指与数据主体直接或间接相关的商业交易信息，可以从该信息或数据用户拥有的该信息和其他信息中识别或识别该数据主体，包括任何敏感的个人数据，以及表达有关数据主体的意见。有关或识别作为数据主体的个人的此类个人信息包括但不限于个人名称，地址，身份证号码，护照号码，电话和/或传真号码，照片，指纹，以及电子邮件和/或社交媒体地址。',
  ruleOneBulletTwo:
    '有关个人数据的处理是指收集，记录，保存或存储个人数据，或对个人数据进行任何操作或一组操作。',
  ruleOneBulletThree:
    '敏感的个人数据是指包含以下信息的任何个人信息，包括但不限于数据主体的身体或精神健康或状况，其政治见解，其宗教信仰或其他类似性质的信仰，他所委托或所称的委托任何违法行为或任何其他个人数据。',
  ruleOneBulletFour: '数据主体是指作为个人数据主体的个人。',

  // rule 2
  ruleTwo: '02. 通知与选择',
  ruleTwoBulletOne:
    'es2move 直接从数据主体收集个人数据，在这种情况下，es2move 通过在线申请，注册或反馈表格从您那里收集个人数据。',

  ruleTwoBulletTwo:
    'es2move 处理各种类型的个人数据，包括但不限于个人名称，地址，身份证号码，护照号码，电话号码和/或传真号码，照片，指纹和电子邮件地址和/或社交媒体地址。',
  ruleTwoBulletThree: 'es2move 将用个人数据来处理以下目的（可能还包括与所述目的直接相关的目的）：',
  ruleTwoBulletThreeDetailOne:
    '为您提供 es2move 业务下的服务，其中包括与商品或服务的供应或交换有关的任何事宜；',
  ruleTwoBulletThreeDetailTwo: '根据任何 es2move 计划处理您的申请或注册；和',
  ruleTwoBulletThreeDetailThree:
    '为行使或履行法律对 es2move 与应聘者有关的法律赋予或施加的任何权利或义务，包括可能从该个人数据中识别出的与其他个人有关的个人数据。',
  ruleTwoBulletFour: 'es2move 可能出于合法商业目的（包括出于以下目的或在以下情况下）向第三方披露个人数据：',
  ruleTwoBulletFourDetailOne: '对求职者进行背景核查；',
  ruleTwoBulletFourDetailTwo: '预期并在实际或潜在的销售，重组，合并，合并或合并全部或部分业务或运营过程中；',
  ruleTwoBulletFourDetailThree: '与 es2move 有关的广告，宣传，市场营销和传播；',
  ruleTwoBulletFourDetailFour:
    '为了行使或履行法律对与应聘者（包括您提供的个人资料的任何裁判）有关的法律或法规赋予 es2move 的权利或义务；并根据法律或具有司法管辖权的法院的命令，或任何要披露的任何监管或政府机构或任何其他相关机构的任何规则，指示或规定，进行要求的披露。',
  ruleTwoBulletFive:
    'es2move 谨此通知您，您有义务提供个人数据，以便我们在 es2move 的业务范围内向您提供服务，包括与商品或服务的供应或交换有关的任何事宜，以处理您的申请或注册根据任何 es2move 的计划，并处理与 es2move 下的就业申请相关的申请。如果您未能提供个人数据，es2move 将无法为您提供适当的服务。',
  ruleTwoBulletSix:
    '如果提供了敏感的个人数据并由您向 es2move 披露，则通过此类披露，您已同意 es2move 对其进行收集和处理。',

  // rule 3
  ruleThree: '03. 信息披露',
  ruleThreeDesc:
    '未经您的同意，将不得出于除收集个人数据时披露个人数据的目的或与之直接相关的目的以外的任何目的披露个人数据，或将其披露给第三方 而不是上面“通知和选择”部分中指定的内容。',

  // rule 4
  ruleFour: '04. 安全',
  ruleFourDesc:
    'es2move 在处理个人数据时应采取适当措施，以保护个人数据免受任何丢失，滥用，修改，未授权或意外干扰或泄露，更改或破坏。',

  // rule 5
  ruleFive: '05. 保留',
  ruleFiveDesc: 'es2move 保留其合法业务目的所必需的个人数据，以及适用法律另行许可。',

  // rule 6
  ruleSix: '06. 数据完整性',
  ruleSixBulletOne: '您提供给 es2move 的个人数据应准确，完整且无误导。',
  ruleSixBulletTwo:
    '您应通知 es2move 来告知 es2move 您对 es2move 提供的个人数据的任何更改，以确保个人数据保持最新。 请参阅下面的“访问”部分以获取可用的通信方法。',

  // rule 7
  ruleSeven: '07. 访问',
  ruleSevenDescOne:
    '您有权要求 es2move 处理的您的个人数据，并能够更正您的个人数据。 您可以通过以下地址或电子邮件将请求发送到 es2move。',
  ruleSevenDescTwo: '电子邮件：enquiry@es2move.com',

  // rule 8
  ruleEight: '08. 查询',
  ruleEightDesc: '有关处理个人数据的任何查询都可通过电子邮件发送到 es2move，网址为 enquiry@es2move.com',
}

const EN = {
  privacyPolicy: 'Privacy Policy',
  // rule 1
  ruleOne: '01. General',
  ruleOneDesc: 'The list below is the terms and conditions of carriage:',
  ruleOneBulletOne:
    'Personal data means information in respect of commercial transactions that relates to directly or indirectly to a Data Subject, who is identified or identifiable from that information or from that and other information in the possession of a data user, including any sensitive personal data, and expression of opinion about the Data Subject. Such personal information about or which identifies individual(s) who is the Data Subject, includes but not limited to individual(s)’ names, addresses, identity card numbers, passport numbers, telephone and/or facsimile numbers, photographs, finger prints, and email and/or social media addresses.',
  ruleOneBulletTwo:
    'Processing in relation to personal data means collecting, recording, holding or storing the personal data or carrying out any operations or set of operations on the personal data.',
  ruleOneBulletThree:
    'Sensitive personal data means any personal information consisting of information including but not limited to the physical or mental health or condition of a Data Subject, his political opinions, his religious beliefs or other beliefs of a similar nature, the commission or alleged commission by him of any offence or any other personal data.',
  ruleOneBulletFour: 'Data Subject refers to individual who is the subject of the personal data.',

  // rule 2
  ruleTwo: '02. Notice and Choice',
  ruleTwoBulletOne:
    'es2move collects personal data directly from the Data Subject, and in this context, from you through online application, registration or feedback form.',
  ruleTwoBulletTwo:
    'es2move processes various types of personal data including but not limited to individual’s names, addresses, identity card numbers, passport numbers, telephone numbers and/or facsimile numbers, photographs, finger prints and e-mail addresses and/or social media addresses.',
  ruleTwoBulletThree:
    'es2move shall process the personal data for the following purposes (which may also include purposes directly related to the said purposes):',
  ruleTwoBulletThreeDetailOne:
    'providing you with services under es2move’s business which includes any matters relating to the supply or exchange of goods or services;',
  ruleTwoBulletThreeDetailTwo: 'processing your application or registration under any es2move’s program; and',
  ruleTwoBulletThreeDetailThree:
    'for the purposes of exercising or performing any right or obligation which is conferred or imposed by law on es2move in connection with candidates for employment including personal data relating to other individuals who may be identified from that personal data.',
  ruleTwoBulletFour:
    'es2move may disclose personal data to third parties for legitimate business purposes, including for the following purposes or in the following circumstances:',
  ruleTwoBulletFourDetailOne: 'background verification checks on candidates for employment;',
  ruleTwoBulletFourDetailTwo:
    'in anticipation of, and in the course of an actual or potential sale, reorganization, consolidation, merger, or amalgamation of all or part of our business or operations;',
  ruleTwoBulletFourDetailThree:
    'relating to advertising, publicity, marketing and communication relating to es2move;',
  ruleTwoBulletFourDetailFour:
    'for the purposes of exercising or performing any right or obligation which is conferred or imposed by law on es2move in connection with candidates for employment including any referee whose personal data are provided by you; and making such disclosures as may be required by law or by order of a court of competent jurisdiction or by any rule, direction or regulation of any regulatory or governmental authority or any other relevant authority to be disclosed.',
  ruleTwoBulletFive:
    'es2move would like to notify that it is obligatory for you to supply the personal data in order for us to provide you with services under es2move’s business which includes any matters relating to the supply or exchange of goods or services, to process your application or registration under any es2move’s program, and to process your application in relation to employment application under es2move. es2move will not be able to provide you with adequate services if you fail to supply the personal data.',
  ruleTwoBulletSix:
    'Where sensitive personal data is provided, and disclosed by you to es2move, by such disclosure, you have consented to its collection, and processing by es2move.',

  // rule 3
  ruleThree: '03. Disclosure',
  ruleThreeDesc:
    'Personal data shall not without your consent be disclosed for any purpose other than the purpose for which the personal data was to be disclosed at the time of collection of the personal data or a purpose directly related to it, or be disclosed to a third party other than that specified in the “Notice and Choice” section above.',

  // rule 4
  ruleFour: '04. Security',
  ruleFourDesc:
    'es2move shall take appropriate measures to protect the personal data from any loss, misuse, modification, unauthorized or accidental access or disclosure, alteration or destruction when processing personal data.',

  // rule 5
  ruleFive: '05. Retention',
  ruleFiveDesc:
    'es2move retains personal data for as long as necessary for its legitimate business purposes, and as otherwise permitted by applicable law.',

  // rule 6
  ruleSix: '06. Data Integrity',
  ruleSixBulletOne:
    'Personal data supplied by you to es2move shall be accurate, complete, and not misleading.',
  ruleSixBulletTwo:
    'You shall by notice in writing to es2move inform es2move of any changes to the personal data supplied by you to es2move to ensure the personal data is kept up-to-date. Please refer to the “Access” section below for the communication methods available.',

  // rule 7
  ruleSeven: '07. Access',
  ruleSevenDescOne:
    'You have the right to request access to your personal data processed by es2move, and be able to correct your personal data. You may send your request to es2move at the following address or e-mail.',
  ruleSevenDescTwo: 'Email: enquiry@es2move.com',

  // rule 8
  ruleEight: '08. Inquiries',
  ruleEightDesc:
    'Any inquiries to the processing of personal data shall be submitted to es2move by e-mail at enquiry@es2move.com',
}

export default {
  ZH_CN,
  EN,
}
