import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { Divider } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { MALAYSIA_POSTCODE, isValidMalaysiaStateAndPostcode } from '../../utils/function/malaysiaPostcode'

import QuickQuoteBackground from '../../assets/images/QuickQuote.svg'
import Money from '../../assets/icons/money.svg'
import QuickQuoteIcon from '../../assets/icons/quickQuoteIcon.svg'

const I18n = require('react-redux-i18n').I18n
const querystring = require('query-string')
const classNames = require('classnames')
const useStyles = makeStyles({
  root: {
    textTransform: 'none',
  },
})
const options = ['orderTypeDailySupplies', 'orderTypeFood', 'orderTypeLargeGoods', 'orderTypeEE']
function New(props) {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const { handleEvaluate } = props
  const [fromState, setFromState] = useState('Johor')
  const [toState, setToState] = useState('Perak')
  const [fromPostcodeList, setFromPostcodeList] = useState([])
  const [fromPostcode, setFromPostcode] = useState('')
  const [toPostcodeList, setToPostcodeList] = useState([])
  const [toPostcode, setToPostcode] = useState('')
  const [weight, setWeight] = useState('')
  const [quantity, setQuantity] = useState('')
  const [size, setSize] = useState(0)
  const [length, setLength] = useState(20)
  const [width, setWidth] = useState(20)
  const [height, setHeight] = useState(30)
  const [nextStep, setNextStep] = useState(false)
  const [price, setPrice] = useState('')
  const [deliveryType, setDeliveryType] = useState('Standard')
  const [isValidFromPostcode, setIsValidFromPostcode] = useState(true)
  const [isValidToPostcode, setIsValidToPostcode] = useState(true)
  const [isValidWeight, setIsValidWeight] = useState(true)
  const [isValidQuantity, setIsValidQuantity] = useState(true)
  const [isValidLength, setIsValidLength] = useState(true)
  const [isValidWidth, setIsValidWidth] = useState(true)
  const [isValidHeight, setIsValidHeight] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [stateList] = useState(() => {
    return Object.keys(MALAYSIA_POSTCODE)
  })
  const [itemType, setItemType] = useState('Daily Supplies')
  useEffect(() => {
    if (fromState) {
      setFromPostcodeList(MALAYSIA_POSTCODE[fromState])
    }
  }, [fromState])

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit')
  }
  useEffect(() => {
    const params = querystring.parse(location.search)
    setItemType(i18nQuote(params.item))
    setDeliveryType(params.delivery === 'true' ? 'Standard' : 'Express')
  }, [location])

  const handleFromPostcodeBlur = () => {
    setIsValidFromPostcode(isValidMalaysiaStateAndPostcode(fromState, fromPostcode))
  }

  const handleToPostcodeBlur = () => {
    setIsValidToPostcode(isValidMalaysiaStateAndPostcode(toState, toPostcode))
  }

  const handleWeightBlur = () => {
    setIsValidWeight(weight !== '')
  }

  const handleQuantityBlur = () => {
    setIsValidQuantity(quantity !== '')
  }

  const handleLengthBlur = () => {
    setIsValidLength(length !== '')
  }

  const handleWidthBlur = () => {
    setIsValidWidth(width !== '')
  }

  const handleHeightBlur = () => {
    setIsValidHeight(height !== '')
  }

  const handleClear = () => {
    setFromState('')
    setFromPostcode('')
    setFromPostcodeList([])
    setToState('')
    setToPostcode('')
    setToPostcodeList([])
    setWeight('')
    setQuantity('')
    setLength('')
    setWidth('')
    setHeight('')
    setItemType('Daily Supplies')
  }
  const checkFormInputValid = () => {
    handleFromPostcodeBlur()
    handleToPostcodeBlur()
    handleWeightBlur()
    handleQuantityBlur()
    handleLengthBlur()
    handleWidthBlur()
    handleHeightBlur()
  }

  const isFormComplete =
    isValidFromPostcode &&
    isValidToPostcode &&
    isValidWeight &&
    isValidQuantity &&
    isValidLength &&
    isValidWidth &&
    isValidHeight &&
    toPostcode !== '' &&
    fromPostcode !== '' &&
    weight !== '' &&
    quantity !== '' &&
    length !== '' &&
    width !== '' &&
    height !== ''

  const handleNext = async (e) => {
    e.preventDefault()
    checkFormInputValid()
    if (isFormComplete) {
      const data = {
        PostCodeFrom: fromPostcode,
        PostCodeTo: toPostcode,
        Quantity: quantity,
        PricingGroup: deliveryType === 'Standard' ? 1 : 2,
        Weight: weight || '',
        Length: length,
        Width: width || '',
        Height: height || '',
      }
      const result = await handleEvaluate(data)
      setPrice(result)
      setNextStep(true)
      window.scrollTo(0, 0)
    }
  }

  const sentReqToService = () => {
    const lang = window.localStorage.getItem('lang')
    if (lang === 'en') {
      const msgSend = `Hi Es2move, here is my order details below:\n* Route\n\tFrom: ${fromPostcode}, ${fromState}\n\tTo: ${toPostcode}, ${toState}\n* Shipments Details\n\tWeight: ${weight} kg\n\tQuantity: ${quantity}\n\tLength X Width X Height: ${length}cm X ${width}cm X ${height}cm \n\tPackage Type: ${itemType}\n\tDelivery Type: ${deliveryType}\n-\nEstimated Price:\nRM ${price}`
      window.open(`https://wa.me/60167758895?text=${encodeURIComponent(msgSend)}`)
    } else if (lang === 'zh-cn') {
      const msgSend = `嗨 Es2move, 以下为我的订单内容：\n* 送货路线 \n\t取货地点：${fromPostcode}, ${fromState}\n\t送达地点：${toPostcode}, ${toState}\n* 托运细节\n\t重量：${weight} 公斤\n\t数量：${quantity}\n\t长 X 宽 X 高：${length} 公分 X ${width} 公分 X ${height} 公分 \n\t货物种类：${itemType}\n\t运送种类：${i18nQuote(
        `order${deliveryType}`,
      )}\n-\n预估价格：\nRM ${price}`
      window.open(`https://wa.me/60167758895?text=${encodeURIComponent(msgSend)}`)
    }
  }

  const i18nQuote = (label) => I18n.t('quickquote')[label]

  useEffect(() => {
    if (toState) {
      setToPostcodeList(MALAYSIA_POSTCODE[toState])
    }
  }, [toState])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    setIsActive(deliveryType === 'Express')
  }, [deliveryType])

  useEffect(() => {
    switch(size){
      case 0:
        setLength(20)
        setWidth(20)
        setHeight(30)
        break
      case 1:
        setLength(30)
        setWidth(30)
        setHeight(40)
        break
      case 2:
        setLength(40)
        setWidth(40)
        setHeight(50)
        break
      case 3:
        setLength('')
        setWidth('')
        setHeight('')
        break
      default:
    }
  }, [size])
  return (
    <body>
      <div className="container-fluid __page__main">
        <div className="row container-es2move adjusted-container">
          <div className="__page__main__title col-lg-6 col-12">
            <text>
              {i18nQuote('titleOne')}
              <br id="title-break-one" />
              {i18nQuote('titleTwo')}
              <br id="title-break-two" />
              {i18nQuote('titleThree')}
             </text>
          </div>
        </div>

        {/* background img in laptop device */}
        <div className="row">
          <div className="__page__main__bg laptop">
            <img src={QuickQuoteBackground} alt="quickquote" />
          </div>
        </div>

        <div className="__page__main__body col-lg-6 col-12">
          {nextStep ? (
            <form onSubmit={handleSubmit}>
              <div className="__page__main__form">
                <div className="row __page__main__form-price">
                  <div className="col-md-7 d-flex col-12">
                    <img src={Money}></img>
                    <text id="quote-title">{i18nQuote('estimatedPrice')}</text>
                  </div>
                  <div className="col-md-5 col-12 text-md-right margin-md">
                    <text id="price" className={classNames({ active: isActive })}>{`RM ${price}`}</text>
                  </div>
                </div>
                <Divider id="divider" />
                <div className={classNames('__page__main__form-title', { active: isActive })}>
                  <div className="result-page">{i18nQuote('route')}</div>
                </div>
                <div className="row __page__main__form-detail">
                  <div className="col-md-6 col-12">
                    <text id="label">{i18nQuote('routeFrom')}</text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">
                      {fromPostcode}, {fromState}
                    </text>
                  </div>
                </div>
                <div className="row __page__main__form-detail justify-content-between">
                  <div className="col-md-6 col-12">
                    <text id="label">{i18nQuote('routeTo')}</text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">
                      {toPostcode}, {toState}
                    </text>
                  </div>
                </div>
                <div className={classNames('__page__main__form-title', { active: isActive })}>
                  <div className="result-page">{i18nQuote('shipmentsDetails')}</div>
                </div>
                <div className="row __page__main__form-detail">
                  <div className="col-md-6 col-12">
                    <text id="label">{i18nQuote('weight')}</text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">
                      {weight} {i18nQuote('kg')}
                    </text>
                  </div>
                </div>
                <div className="row __page__main__form-detail">
                  <div className="col-md-6 col-12">
                    <text id="label">{i18nQuote('quantity')}</text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">{quantity}</text>
                  </div>
                </div>
                <div className="row __page__main__form-detail">
                  <div className="col-md-6 col-12">
                    <text id="label">
                      {i18nQuote('length')} X {i18nQuote('width')} X {i18nQuote('height')}
                    </text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">
                      {length}
                      {i18nQuote('cm')} X {width}
                      {i18nQuote('cm')} X {height}
                      {i18nQuote('cm')}
                    </text>
                  </div>
                </div>
                <div className="row __page__main__form-detail">
                  <div className="col-md-6 col-12">
                    <text id="label">{i18nQuote('packageType')}</text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">{itemType}</text>
                  </div>
                </div>
                <div className="row __page__main__form-detail last">
                  <div className="col-md-6 col-12">
                    <text id="label">{i18nQuote('deliveryType')}</text>
                  </div>
                  <div className="col-md-6 col-12 text-md-right">
                    <text id="detail">{i18nQuote(`order${deliveryType}`)}</text>
                  </div>
                </div>
                <Divider id="divider" />
                <div className="row justify-content-end __page__main__form-submit">
                  <div className="col-md-6 col-12 margin-md">
                    <Button
                      fullWidth
                      className={classNames(classes.root, 'clear__button')}
                      onClick={() => setNextStep(false)}
                    >
                      {i18nQuote('backToQQ')}
                    </Button>
                  </div>
                  <div className="col-md-5 col-12 order">
                    <Button
                      fullWidth
                      className={classNames(
                        'submit__button',
                        classes.root,
                        deliveryType === 'Standard' ? '' : 'active',
                      )}
                      variant="contained"
                      type="submit"
                      onClick={() => sentReqToService()}
                    >
                      <span>{i18nQuote('contactUs')}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="__page__main__form">
              <div className="row __page__main__form-control">
                <div className="col-md-6 d-flex col-12 form-category">
                  <img src={QuickQuoteIcon} alt="quick-quote-icon"/>
                  <Select
                    native={isMobile ? true : false}
                    disableUnderline
                    value={itemType}
                    onChange={(e) => setItemType(e.target.value)}
                  >
                    {options.map((item) => {
                      return isMobile ? (
                        <option value={i18nQuote(item)}>{i18nQuote(item)}</option>
                      ) : (
                        <MenuItem value={i18nQuote(item)}>{i18nQuote(item)}</MenuItem>
                      )
                    })}
                  </Select>
                </div>
                <div className="col-md-6 col-12 margin-btn">
                  <button
                    className={classNames(
                      '__body__prices__type__btn',
                      'left',
                      'btn',
                      deliveryType === 'Standard' ? 'active' : 'express',
                    )}
                    onClick={() => setDeliveryType('Standard')}
                  >
                    <text>{i18nQuote('orderStandard')}</text>
                  </button>
                  <button
                    className={classNames(
                      '__body__prices__type__btn',
                      'right',
                      'btn',
                      deliveryType === 'Standard' ? '' : 'active express',
                    )}
                    onClick={() => setDeliveryType('Express')}
                  >
                    <text>{i18nQuote('orderExpress')}</text>
                  </button>
                </div>
              </div>
              <Divider id="divider" />
              <div className={classNames('__page__main__form-title', { active: isActive })}>
                {i18nQuote('route')}
              </div>
              <div className="row __page__main__form-body first">
                <div className="col-md-6 col-12 p-r-mod">
                  <InputLabel id="fromStateLabel">{i18nQuote('routeFrom')}</InputLabel>
                  <Select
                    className="form-select"
                    native={isMobile ? true : false}
                    fullWidth
                    labelId="fromStateLabel"
                    value={fromState}
                    onChange={(e) => {
                      setFromState(e.target.value)
                    }}
                  >
                    {stateList.map((item) => {
                      return isMobile ? (
                        <option value={item}>{item}</option>
                      ) : (
                        <MenuItem value={item}>{item}</MenuItem>
                      )
                    })}
                  </Select>
                </div>
                <div className="col-md-6 col-12 margin-md p-l-mod">
                  <InputLabel
                    id="fromPostCodeLabel"
                    className={classNames(!isValidFromPostcode && 'invalid-label')}
                  >
                    {i18nQuote('postcode')}
                  </InputLabel>
                  <Autocomplete
                    className="input-field"
                    disablePortal
                    clearOnBlur={false}
                    id="from-postcode-options"
                    disabled={fromState === ''}
                    options={fromPostcodeList}
                    getOptionLabel={(option) => option}
                    value={fromPostcode}
                    onInputChange={(e, fromPostcode) => {
                      setFromPostcode(fromPostcode)
                    }}
                    onChange={(e, fromPostcode) => {
                      setFromPostcode(fromPostcode)
                    }}
                    onBlur={handleFromPostcodeBlur}
                    renderInput={(params) =>
                      isValidFromPostcode ? (
                        <TextField {...params} margin="none" />
                      ) : (
                        <TextField {...params} margin="none" error helperText={i18nQuote('errPostcode')} />
                      )
                    }
                  />
                </div>
              </div>
              <div className="row __page__main__form-body second" >
                <div className="col-md-6 col-12 p-r-mod">
                  <InputLabel id="toStateLabel">{i18nQuote('routeTo')}</InputLabel>
                  <Select
                    native={isMobile ? true : false}
                    fullWidth
                    labelId="toStateLabel"
                    value={toState}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setToState(e.target.value)
                      setToPostcode('')
                    }}
                  >
                    {stateList.map((item) => {
                      return isMobile ? (
                        <option value={item}>{item}</option>
                      ) : (
                        <MenuItem value={item}>{item}</MenuItem>
                      )
                    })}
                  </Select>
                </div>
                <div className="col-md-6 col-12 margin-md p-l-mod">
                  <InputLabel
                    id="toPostcodeLabel"
                    className={classNames(!isValidToPostcode && 'invalid-label')}
                  >
                    {i18nQuote('postcode')}
                  </InputLabel>
                  <Autocomplete
                    className="input-field"
                    disablePortal
                    clearOnBlur={false}
                    id="to-postcode-options"
                    disabled={toState == ''}
                    options={toPostcodeList}
                    getOptionLabel={(option) => option}
                    value={toPostcode}
                    onInputChange={(e, toPostcode) => {
                      setToPostcode(toPostcode)
                    }}
                    onChange={(e, toPostcode) => {
                      setToPostcode(toPostcode)
                    }}
                    onBlur={handleToPostcodeBlur}
                    renderInput={(params) =>
                      isValidToPostcode ? (
                        <TextField {...params} margin="none" />
                      ) : (
                        <TextField {...params} margin="none" error helperText={i18nQuote('errPostcode')} />
                      )
                    }
                  />
                </div>
              </div>
              <div className={classNames('__page__main__form-title', 'second', { active: isActive })}>
                {i18nQuote('shipmentsDetails')}
              </div>
              <div className="row __page__main__form-body">
                <div className="col-md-6 col-12 margin-md p-r-mod first">
                  <InputLabel id="Weight" className={classNames(!isValidWeight && 'invalid-label')}>
                    {i18nQuote('weight')}
                  </InputLabel>
                  <TextField
                    className="input-field"
                    fullWidth
                    type="number"
                    labelId="Weight"
                    placeholder={i18nQuote('kg')}
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    InputProps={{ endAdornment: weight !== '' && i18nQuote('kg') }}
                    onBlur={handleWeightBlur}
                    error={!isValidWeight}
                    helperText={!isValidWeight ? i18nQuote('errNumber') : ''}
                  />
                </div>
                <div className="col-md-6 col-12 margin-md p-l-mod ship-detail">
                  <InputLabel id="Quantity" className={classNames(!isValidQuantity && 'invalid-label')}>
                    {i18nQuote('quantity')}
                  </InputLabel>
                  <TextField
                    className="input-field"
                    fullWidth
                    type="number"
                    labelId="Quantity"
                    placeholder="1"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    onBlur={handleQuantityBlur}
                    error={!isValidQuantity}
                    helperText={!isValidQuantity ? i18nQuote('errNumber') : ''}
                  />
                </div>
              </div>
              <div className="row __page__main__form-body">
                <div className="col-12 p-r-mod ship-detail">
                  <InputLabel id="size">
                    {i18nQuote('size')}
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="size"
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                  >
                    <MenuItem value={0}>Small 20cm X 20cm X 30cm</MenuItem>
                    <MenuItem value={1}>Medium 30cm X 30cm X 40cm</MenuItem>
                    <MenuItem value={2}>Large 40cm X 40cm X 50cm</MenuItem>
                    <MenuItem value={3}>Other Size</MenuItem>
                  </Select>
                </div>
              </div>
              {size === 3 && (
              <div className="row __page__main__form-body">
                <div className="col-md-4 col-12 margin-md p-r-mod ship-detail">
                  <InputLabel id="Length" className={classNames(!isValidLength && 'invalid-label')}>
                    {i18nQuote('length')}
                  </InputLabel>
                  <TextField
                    className="input-field"
                    fullWidth
                    type="number"
                    labelId="Length"
                    placeholder={i18nQuote('cm')}
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                    InputProps={{ endAdornment: length !== '' && i18nQuote('cm') }}
                    onBlur={handleLengthBlur}
                    error={!isValidLength}
                    helperText={!isValidLength ? i18nQuote('errNumber') : ''}
                  />
                </div>
                <div className="col-md-4 col-12 margin-md p-r-mod p-l-mod ship-detail">
                  <InputLabel id="Width" className={classNames(!isValidWidth && 'invalid-label')}>
                    {i18nQuote('width')}
                  </InputLabel>
                  <TextField
                    className="input-field"
                    fullWidth
                    type="number"
                    labelId="Width"
                    placeholder={i18nQuote('cm')}
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    InputProps={{ endAdornment: width !== '' && i18nQuote('cm') }}
                    onBlur={handleWidthBlur}
                    error={!isValidWidth}
                    helperText={!isValidWidth ? i18nQuote('errNumber') : ''}
                  />
                </div>
                <div className="col-md-4 col-12 margin-md p-l-mod ship-detail">
                  <InputLabel id="Height" className={classNames(!isValidHeight && 'invalid-label')}>
                    {i18nQuote('height')}
                  </InputLabel>
                  <TextField
                    className="input-field"
                    fullWidth
                    type="number"
                    labelId="Height"
                    placeholder={i18nQuote('cm')}
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    InputProps={{ endAdornment: height !== '' && i18nQuote('cm') }}
                    onBlur={handleHeightBlur}
                    error={!isValidHeight}
                    helperText={!isValidHeight ? i18nQuote('errNumber') : ''}
                  />
                </div>
              </div>
              )}
              <div className="row justify-content-end __page__main__form-submit">
                <div className={classNames('col-md-5', 'col-12', 'clear-btn-div', 'd-none', 'd-md-block')}>
                  <Button
                    size="large"
                    fullWidth
                    className={classNames('clear__button', classes.root)}
                    onClick={handleClear}
                  >
                    <span>{i18nQuote('clear')}</span>
                  </Button>
                </div>
                <div className="col-md-5 col-12 submit-btn-div">
                  <Button
                    size="large"
                    fullWidth
                    className={classNames('submit__button', classes.root, { active: isActive })}
                    variant="contained"
                    onClick={handleNext}
                  >
                    <span>{i18nQuote('getPrice')}</span>
                  </Button>
                </div>
                <div className={classNames('col-md-5', 'col-12', 'clear-btn-div', 'd-md-none')}>
                  <Button
                    size="large"
                    fullWidth
                    className={classNames('clear__button', classes.root)}
                    onClick={handleClear}
                  >
                    <span>{i18nQuote('clear')}</span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* background img in mobile device */}
        <div className="row">
          <div className="__page__main__bg mobile">
            <img src={QuickQuoteBackground} alt="quickquote" />
          </div>
        </div>
      </div>
    </body>
  )
}

New.propTypes = {
  handleEvaluate: PropTypes.func.isRequired,
}

export default New
