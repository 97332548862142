const ZH_CN = {
  // search result
  title: '这是您的订单状态！',
  copyDesc: '您可以复制连结来分享您的订单状态。',
  copy: '复制连结',
  copySuccess: '您已经复制连结。',
  number: '订单编号',
  status: '状态：',
  created: '已建立',
  progressing: '处理中',
  inTransit: '运送中',
  arrived: '已配达',
  cancelled: '已取消',
  contact: '联系客服',
  backHome: '返回首页',
  inTransitToWarehouse : '前往物流中心',
  outOfDelivery:'正在派送',
  delivered:'已抵达',

  // search status
  searchTitle: '搜寻订单状态',
  searchDesc: '请输入订单编号来搜寻您的订单状态',

  // error text
  invalidTitle: '无效的订单编号',
  invalidDesc: '请重新搜寻。',
}

const EN = {
  // search result
  title: 'Here is Your Order Status!',
  copyDesc: 'You can click the link to share your order status.',
  copy: 'Copy Link',
  copySuccess: 'You have already copied the link.',
  number: 'No.',
  status: 'Status: ',
  created: 'Created',
  progressing: 'Progressing',
  inTransit: 'In Transit',
  arrived: 'Arrived',
  cancelled: 'Cancelled',
  contact: 'Contact Us',
  backHome: 'Back to Home Page',
  inTransitToWarehouse:'In Transit to Warehouse',
  outOfDelivery:'Out of Delivery',
  delivered:'Delivered',


  // search status
  searchTitle: 'Search Order Status',
  searchDesc: 'Please enter te order No. to ceck your order status.',

  // error text
  invalidTitle: 'Invalid Order No.',
  invalidDesc: 'Please search again.',
}

export default {
  ZH_CN,
  EN,
}
