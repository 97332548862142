// Internationalization -import i18n
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import { LanguageTable } from '../i18n/i18n'
import { store } from '../store'

// Internationalization - default language
let language = (
  window.localStorage.getItem('lang') ||
  navigator.language ||
  navigator.browserLanguage
).toLowerCase()

export const setLanguage = () => {
  // Internationalization - dispatch language
  language = language !== 'zh-cn' ? 'en' : language
  console.log(language);
  syncTranslationWithStore(store)
  store.dispatch(loadTranslations(LanguageTable))
  store.dispatch(setLocale(language))
}

export const chgLanguage = (lang) => {
  syncTranslationWithStore(store)
  store.dispatch(setLocale(lang))
}
