import React from 'react'

const Translate = require('react-redux-i18n').Translate

// wording
export const refundTitle = <Translate value="refund.refundTitle" />
export const refundDescOne = <Translate value="refund.refundDescOne" />
export const refundTwo = <Translate value="refund.refundDescTwo" />

export const contactInfo = <Translate value="refund.contactInfo" />
export const name = <Translate value="refund.name" />
export const contactNumber = <Translate value="refund.contactNumber" />
export const message = <Translate value="refund.message" />
export const submit = <Translate value="refund.submit" />
export const clear = <Translate value="refund.clear" />

export const success = <Translate value="refund.success" />
