import Home from './views/Home/Home'
import Driver from './views/Driver/Driver'
import Refund from './views/Refund/Refund'
import Search from './views/SearchOrder/SearchOrder'
import PrivacyPolicy from './views/Policy/PolicyPage/PrivacyPolicy'
import TermsAndConditions from './views/Policy/PolicyPage/TermsAndConditions'
import ProhibitedItems from './views/Policy/PolicyPage/ProhibitedItems'
import QuickQuote from './views/QuickQuote/NewContainer'
const Role = {
    route: [
        {path: '/policy/privacy', name: 'policyPrivacy', component: PrivacyPolicy},
        {path: '/policy/term', name: 'policyTerm', component: TermsAndConditions},
        {path: '/policy/prohibited', name: 'policyProhibited', component: ProhibitedItems},
        {path: '/search', name: 'search', component: Search},
        {path: '/driver', name: 'driver', component: Driver},
        {path: '/refund', name: 'refund', component: Refund},
        {path: '/', name: 'home', component: Home},
        {path: '/quickquote', name: 'quickquote', component: QuickQuote}
    ]
}

export default{
    Role
};
