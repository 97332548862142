import React from 'react'

const Translate = require('react-redux-i18n').Translate

// wording
export const PrivacyPolicy = {
  privacyPolicy: <Translate value="privacyPolicy.privacyPolicy" />,

  ruleOne: <Translate value="privacyPolicy.ruleOne" />,
  ruleOneDesc: <Translate value="privacyPolicy.ruleOneDesc" />,
  ruleOneBulletOne: <Translate value="privacyPolicy.ruleOneBulletOne" />,
  ruleOneBulletTwo: <Translate value="privacyPolicy.ruleOneBulletTwo" />,
  ruleOneBulletThree: <Translate value="privacyPolicy.ruleOneBulletThree" />,
  ruleOneBulletFour: <Translate value="privacyPolicy.ruleOneBulletFour" />,

  ruleTwo: <Translate value="privacyPolicy.ruleTwo" />,
  ruleTwoBulletOne: <Translate value="privacyPolicy.ruleTwoBulletOne" />,
  ruleTwoBulletTwo: <Translate value="privacyPolicy.ruleTwoBulletTwo" />,
  ruleTwoBulletThree: <Translate value="privacyPolicy.ruleTwoBulletThree" />,
  ruleTwoBulletThreeDetailOne: <Translate value="privacyPolicy.ruleTwoBulletThreeDetailOne" />,
  ruleTwoBulletThreeDetailTwo: <Translate value="privacyPolicy.ruleTwoBulletThreeDetailTwo" />,
  ruleTwoBulletThreeDetailThree: <Translate value="privacyPolicy.ruleTwoBulletThreeDetailThree" />,
  ruleTwoBulletFour: <Translate value="privacyPolicy.ruleTwoBulletFour" />,
  ruleTwoBulletFourDetailOne: <Translate value="privacyPolicy.ruleTwoBulletFourDetailOne" />,
  ruleTwoBulletFourDetailTwo: <Translate value="privacyPolicy.ruleTwoBulletFourDetailTwo" />,
  ruleTwoBulletFourDetailThree: <Translate value="privacyPolicy.ruleTwoBulletFourDetailThree" />,
  ruleTwoBulletFourDetailFour: <Translate value="privacyPolicy.ruleTwoBulletFourDetailFour" />,
  ruleTwoBulletFive: <Translate value="privacyPolicy.ruleTwoBulletFive" />,
  ruleTwoBulletSix: <Translate value="privacyPolicy.ruleTwoBulletSix" />,

  ruleThree: <Translate value="privacyPolicy.ruleThree" />,
  ruleThreeDesc: <Translate value="privacyPolicy.ruleThreeDesc" />,

  ruleFour: <Translate value="privacyPolicy.ruleFour" />,
  ruleFourDesc: <Translate value="privacyPolicy.ruleFourDesc" />,

  ruleFive: <Translate value="privacyPolicy.ruleFive" />,
  ruleFiveDesc: <Translate value="privacyPolicy.ruleFiveDesc" />,

  ruleSix: <Translate value="privacyPolicy.ruleSix" />,
  ruleSixBulletOne: <Translate value="privacyPolicy.ruleSixBulletOne" />,
  ruleSixBulletTwo: <Translate value="privacyPolicy.ruleSixBulletTwo" />,

  ruleSeven: <Translate value="privacyPolicy.ruleSeven" />,
  ruleSevenDescOne: <Translate value="privacyPolicy.ruleSevenDescOne" />,
  ruleSevenDescTwo: <Translate value="privacyPolicy.ruleSevenDescTwo" />,

  ruleEight: <Translate value="privacyPolicy.ruleEight" />,
  ruleEightDesc: <Translate value="privacyPolicy.ruleEightDesc" />,
}

export const ProhibitedItems = {
  prohibitedItems: <Translate value="prohibitedItems.prohibitedItems" />,

  ruleOne: <Translate value="prohibitedItems.ruleOne" />,
  ruleOneBulletOne: <Translate value="prohibitedItems.ruleOneBulletOne" />,
  ruleOneBulletTwo: <Translate value="prohibitedItems.ruleOneBulletTwo" />,
  ruleOneBulletThree: <Translate value="prohibitedItems.ruleOneBulletThree" />,
  ruleOneBulletFour: <Translate value="prohibitedItems.ruleOneBulletFour" />,
  ruleOneBulletFive: <Translate value="prohibitedItems.ruleOneBulletFive" />,
  ruleOneBulletSix: <Translate value="prohibitedItems.ruleOneBulletSix" />,

  ruleTwo: <Translate value="prohibitedItems.ruleTwo" />,
  ruleTwoBulletOne: <Translate value="prohibitedItems.ruleTwoBulletOne" />,
  ruleTwoBulletTwo: <Translate value="prohibitedItems.ruleTwoBulletTwo" />,
  ruleTwoBulletThree: <Translate value="prohibitedItems.ruleTwoBulletThree" />,
  ruleTwoBulletFour: <Translate value="prohibitedItems.ruleTwoBulletFour" />,
  ruleTwoBulletFive: <Translate value="prohibitedItems.ruleTwoBulletFive" />,
  ruleTwoBulletSix: <Translate value="prohibitedItems.ruleTwoBulletSix" />,
  ruleTwoBulletSeven: <Translate value="prohibitedItems.ruleTwoBulletSeven" />,
  ruleTwoBulletEight: <Translate value="prohibitedItems.ruleTwoBulletEight" />,
  ruleTwoBulletNine: <Translate value="prohibitedItems.ruleTwoBulletNine" />,

}

export const TermsAndCondition = {
  termsConditions: <Translate value="termsAndCondition.termsConditions" />,

  ruleOne: <Translate value="termsAndCondition.ruleOne" />,
  ruleOneBulletOne: <Translate value="termsAndCondition.ruleOneBulletOne" />,
  ruleOneBulletTwo: <Translate value="termsAndCondition.ruleOneBulletTwo" />,
  ruleOneBulletThree: <Translate value="termsAndCondition.ruleOneBulletThree" />,

  ruleTwo: <Translate value="termsAndCondition.ruleTwo" />,
  ruleTwoBulletOne: <Translate value="termsAndCondition.ruleTwoBulletOne" />,
  ruleTwoBulletTwo: <Translate value="termsAndCondition.ruleTwoBulletTwo" />,
  ruleTwoBulletThree: <Translate value="termsAndCondition.ruleTwoBulletThree" />,
  ruleTwoBulletFour: <Translate value="termsAndCondition.ruleTwoBulletFour" />,
  ruleTwoBulletFive: <Translate value="termsAndCondition.ruleTwoBulletFive" />,
  ruleTwoBulletSix: <Translate value="termsAndCondition.ruleTwoBulletSix" />,
  ruleTwoBulletSeven: <Translate value="termsAndCondition.ruleTwoBulletSeven" />,
  ruleTwoBulletEight: <Translate value="termsAndCondition.ruleTwoBulletEight" />,
  ruleTwoBulletNine: <Translate value="termsAndCondition.ruleTwoBulletNine" />,

  ruleThree: <Translate value="termsAndCondition.ruleThree" />,
  ruleThreeBulletOne: <Translate value="termsAndCondition.ruleThreeBulletOne" />,
  ruleThreeBulletTwoDescOne: <Translate value="termsAndCondition.ruleThreeBulletTwoDescOne" />,
  ruleThreeBulletTwoDescTwo: <Translate value="termsAndCondition.ruleThreeBulletTwoDescTwo" />,
  ruleThreeBulletThree: <Translate value="termsAndCondition.ruleThreeBulletThree" />,

  ruleFour: <Translate value="termsAndCondition.ruleFour" />,
  ruleFourBulletOne: <Translate value="termsAndCondition.ruleFourBulletOne" />,
  ruleFourBulletTwo: <Translate value="termsAndCondition.ruleFourBulletTwo" />,
  ruleFourBulletThree: <Translate value="termsAndCondition.ruleFourBulletThree" />,
  ruleFourBulletFour: <Translate value="termsAndCondition.ruleFourBulletFour" />,
  ruleFourBulletFive: <Translate value="termsAndCondition.ruleFourBulletFive" />,
  ruleFourBulletSix: <Translate value="termsAndCondition.ruleFourBulletSix" />,

  ruleFive: <Translate value="termsAndCondition.ruleFive" />,
  ruleFiveBulletOne: <Translate value="termsAndCondition.ruleFiveBulletOne" />,
  ruleFiveBulletTwo: <Translate value="termsAndCondition.ruleFiveBulletTwo" />,
  ruleFiveBulletThree: <Translate value="termsAndCondition.ruleFiveBulletThree" />,
  ruleFiveBulletFour: <Translate value="termsAndCondition.ruleFiveBulletFour" />,
  ruleFiveBulletFive: <Translate value="termsAndCondition.ruleFiveBulletFive" />,
  ruleFiveBulletSix: <Translate value="termsAndCondition.ruleFiveBulletSix" />,
  ruleFiveBulletSeven: <Translate value="termsAndCondition.ruleFiveBulletSeven" />,

  ruleSix: <Translate value="termsAndCondition.ruleSix" />,
  ruleSixBulletOne: <Translate value="termsAndCondition.ruleSixBulletOne" />,
  ruleSixBulletTwo: <Translate value="termsAndCondition.ruleSixBulletTwo" />,
  ruleSixBulletThree: <Translate value="termsAndCondition.ruleSixBulletThree" />,
  ruleSixBulletFour: <Translate value="termsAndCondition.ruleSixBulletFour" />,
  ruleSixBulletFive: <Translate value="termsAndCondition.ruleSixBulletFive" />,
  ruleSixBulletSix: <Translate value="termsAndCondition.ruleSixBulletSix" />,
  ruleSixBulletSeven: <Translate value="termsAndCondition.ruleSixBulletSeven" />,
  ruleSixBulletEight: <Translate value="termsAndCondition.ruleSixBulletEight" />,

  ruleSeven: <Translate value="termsAndCondition.ruleSeven" />,
  ruleSevenBulletOne: <Translate value="termsAndCondition.ruleSevenBulletOne" />,
  ruleSevenBulletTwo: <Translate value="termsAndCondition.ruleSevenBulletTwo" />,
}
