import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'

function ProhibitedItems(props) {
  const { i18n } = props

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  return (
    <>
      <div className="container-fluid __policy" onContextMenu={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-12">
            <div className="__policy__title">
              <text>{i18n.prohibitedItems}</text>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleOne}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleOneBulletOne}</li>
                <li>{i18n.ruleOneBulletTwo}</li>
                <li>{i18n.ruleOneBulletThree}</li>
                <li>{i18n.ruleOneBulletFour}</li>
                <li>{i18n.ruleOneBulletFive}</li>
                <li>{i18n.ruleOneBulletSix}</li>
              </ul>
            </div>
          </div>

          <div className="__policy__billboard col-12">
            <div className="__policy__billboard__title">{i18n.ruleTwo}</div>
            <div className="__policy__billboard__contents">
              <ul>
                <li>{i18n.ruleTwoBulletOne}</li>
                <li>{i18n.ruleTwoBulletTwo}</li>
                <li>{i18n.ruleTwoBulletThree}</li>
                <li>{i18n.ruleTwoBulletFour}</li>
                <li>{i18n.ruleTwoBulletFive}</li>
                <li>{i18n.ruleTwoBulletSix}</li>
                <li>{i18n.ruleTwoBulletSeven}</li>
                <li>{i18n.ruleTwoBulletEight}</li>
                <li>{i18n.ruleTwoBulletNine}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ProhibitedItems.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default ProhibitedItems
