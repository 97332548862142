import React, { useState, useEffect, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'

import Header from '../components/UI/Header'
import Footer from '../components/UI/Footer'

import role_permission from './role_permission'

const i18n = require('./views/Home/HomeContents')

function Full(props) {
  const history = useHistory()

  return (
    <main>
      <Suspense fallback={<div></div>}>
        <Header i18n={i18n} />
        <Switch>
          {role_permission['Role'].route.map((route) => {
            return (
              <Route
                sensitive
                exact
                path={route.path}
                key={route.name}
                name={route.name}
                component={route.component}
              />
            )
          })}
        </Switch>
        <Footer i18n={i18n} />
      </Suspense>
    </main>
  )
}

export default Full
