import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import StepConnector from '@material-ui/core/StepConnector'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import SearchOrderBackground from '../../assets/images/searchOrderBackground.svg'
import SearchOrderImg from '../../assets/images/searchOrderImg.svg'
import ShareOrderLink from '../../assets/icons/shareOrderLink.svg'
import InvalidOrderNo from '../../assets/icons/invalidOrderNo.svg'
import InvalidOrderNoMobile from '../../assets/icons/invalidOrderNoMobile.svg'

import { ORDER_STATUS_TABLE, ORDER_STATUS_CN, ORDER_STATUS_EN } from '../../constants/orderStatus'
import Recaptcha from '../UI/Recaptcha'

const querystring = require('query-string')
const classNames = require('classnames')

function getSteps(i18n) {
  return [i18n.created, i18n.progressing, i18n.inTransitToWarehouse, i18n.outOfDelivery,i18n.delivered]
}

function getStepContent(step, dateContext) {
  switch (step) {
    case 0:
      return `${dateContext[0]}`
    case 1:
      return `${dateContext[1]}`
    case 2:
      return `${dateContext[2]}`
    case 3:
      return `${dateContext[3]}`
    case 4:
      return `${dateContext[4]}`
    default:
      return 'Unknown step'
  }
}

function SearchOrder(props) {
  const { getOrderStatus, i18n } = props
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)
  const [snackbarVertical, setSnackbarVertical] = useState('top')
  const location = useLocation()
  const [orderNumber, setOrderNumber] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const [localErrMsg, setLocalErrMsg] = useState('')
  const [dateContext, setDateContext] = useState(['', '', '', ''])
  const [isOrderNumberValid, setIsOrderNumberValid] = useState(true)
  const [isRobot, setIsRobot] = useState(true)
  const [windowWidth, setWindowWidth] = useState(0)
  const steps = getSteps(i18n)
  const recaptchaRef = React.createRef()
  const [pickUpCity, setPickUpCity] = useState('')
  const [destinationCity, setDestinationCity] = useState('')
  const [destinationReceiverName, setDestinationReceiverName] = useState('')

  const QontoConnector = withStyles({
    active: {
      '& $line': {
        borderColor: '#3397F7',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#3397F7',
      },
    },
    line: {
      borderColor: '#818C9E',
    },
  })(StepConnector)

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth)
  }

  const timeFormatter = (timeStr) => {
    const formattedTime = dayjs(timeStr).format('DD/MM/YYYY HH:mm')
    return formattedTime
  }

  const createOrderTime = (orders) => {
    const orderTime = [timeFormatter(orders[0][1].CreatedTime), '', '', '','']
    
    for (let i = 0; i < orders.length; i++) {
      orderTime[orders[i][0]] = timeFormatter(orders[i][1].UpdatedTime)
    }
    
    for (let i = 0; i < orderTime.length; i++) {
      if (orderTime[i] === '') {
        orderTime[i] = orderTime[i - 1]
      }
    }

    /** 因為目前沒有 Out of Delivery Time，所以先用 Delivered Time，所以會在 i === 3 對應 Out of Delivery 做掉 */
    orderTime[3] = orderTime[4]

    setDateContext(orderTime)
  }

  const handleOrderSearch = async () => {
    const params = querystring.parse(location.search)
    const result = await getOrderStatus({ DeliverOrderNo: params.id })
    const outputOrders = []
    if (result) {
      if (result.length === 1) {
        // single element case
        setIsOrderNumberValid(true)
        outputOrders.push([ORDER_STATUS_TABLE[result[0].DeliverStatusType], result[0]])
      } else {
        // normal case
        setIsOrderNumberValid(true)
        const cleanedOrdersId = []
        // chg to customized status
        result.forEach((ele) => {
          cleanedOrdersId.push([ORDER_STATUS_TABLE[ele.DeliverStatusType], ele])
        })
        // sort orders by their state
        cleanedOrdersId.sort(function (a, b) {
          return a[1] - b[1]
        })

        let tmpDeliverStatus = cleanedOrdersId[0][0]
        let tmpOrder = cleanedOrdersId[0][1]
        for (let i = 1; i <= cleanedOrdersId.length - 1; i++) {
          let curDeliverStatus = cleanedOrdersId[i][0]
          let curOrder = cleanedOrdersId[i][1]
          if (curDeliverStatus > tmpDeliverStatus) {
            outputOrders.push([tmpDeliverStatus, tmpOrder])
            if (i === cleanedOrdersId.length - 1) {
              outputOrders.push([curDeliverStatus, curOrder])
            }
          } else {
            tmpDeliverStatus = curDeliverStatus
            tmpOrder = curOrder
          }
        }
      }
    } else {
      // no matched case
      setIsOrderNumberValid(false)
      console.log('[Error] No matched order')
    }
    // OBJECT STRUCTURE: outputOrders = [DelilverStatusType, Order Content]
    // Set current state and time, and format the time
    if (outputOrders.length !== 0) {
      setOrderNumber(outputOrders[0][1].DeliverOrderNo)
      setCurrentStep(outputOrders[outputOrders.length - 1][0])
      createOrderTime(outputOrders)
      setPickUpCity(outputOrders[0][1]?.PickUpCity ?? '-')
      setDestinationCity(outputOrders[0][1]?.DestinationCity ?? '-')
      setDestinationReceiverName(outputOrders[0][1]?.DestinationReceiverName ?? '-')
    }
  }

  const handleShareLinkClick = () => {
    setOpenSuccessSnackbar(true)
    copyLinkToClickboard()
  }

  const copyLinkToClickboard = () => {
    const el = document.createElement('textarea')
    el.value = `${window.location.href}`
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccessSnackbar(false)
  }

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleChange = (value) => {
    setIsRobot(false)
  }

  function getDetailedInfo(step) {
    switch (step) {
      case 0:
        return `Location: ${pickUpCity}`
      case 1:
        return `Location: ${pickUpCity}`
      case 2:
        return `Location: ${destinationCity}`
      case 3:
        return `Location: ${destinationCity}`
      case 4:
        return `Delivered to: ${destinationReceiverName}`
      default:
        return 'Unknown step'
    }
  }

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => window.removeEventListener('resize', resizeWindow)
  }, [])

  useEffect(() => {
    if (isRobot === false) {
      handleOrderSearch()
    }
  }, [isRobot])

  return (
    <>
      {/* container-es2move */}
      <div className="__search d-flex">
        {isRobot ? (
          <div className="__search__botcheckarea">
            <Recaptcha classes={'__search__recaptcha'} ref={recaptchaRef} onChange={handleChange} />
          </div>
        ) : isOrderNumberValid ? (
          <div className="__search__status">
            <div className="__search__status__title">{i18n.title}</div>
            <div className="__search__status__board">
              <div className="board-header">
                <img src={SearchOrderImg} className="stepper-img" />
                <div className="stepper-text">
                  <div className="status">
                    {i18n.number} {orderNumber} {i18n.status}
                    {window.localStorage.getItem('lang') === 'en' && ORDER_STATUS_EN[currentStep]}
                    {window.localStorage.getItem('lang') === 'zh-cn' && ORDER_STATUS_CN[currentStep]}
                  </div>
                  <div className="share-link">
                    {i18n.copyDesc} <br className="share-link-break" />
                    <a onClick={() => handleShareLinkClick()}>
                      <img src={ShareOrderLink} /> <p>{i18n.copy}</p>
                    </a>
                  </div>
                </div>
              </div>
              <Stepper
                activeStep={currentStep + 1}
                className="stepper-steps"
                alternativeLabel={windowWidth <= 768 ? false : true}
                orientation={windowWidth <= 768 ? 'vertical' : 'horizontal'}
                connector={<QontoConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label} active={index <= currentStep}>
                    <StepLabel className="stepper-label">{label}</StepLabel>
                    <StepContent className={classNames('stepper-content', index < currentStep && 'active')}>
                      <Typography className="mb-2">{getDetailedInfo(index)}</Typography>
                      <Typography>{getStepContent(index, dateContext)}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>

              <div className="__search__status__button d-flex">
                <Button component={Link} to="/" id="laptop" className="home">
                  {i18n.backHome}
                </Button>
                <a href="https://wa.me/60167758895" className="btn btn-primary" id="contact">
                  {i18n.contact}
                </a>
                <Button component={Link} to="/" id="mobile" className="home">
                  {i18n.backHome}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="__search__status invalid">
            <div className="__search__status__board">
              <div className="__search__status__error__img">
                {windowWidth <= 768 ? (
                  <img src={InvalidOrderNoMobile} alt="invalid-order-no" />
                ) : (
                  <img src={InvalidOrderNo} alt="invalid-order-no" />
                )}
              </div>
              <div className="__search__status__error__title">{i18n.invalidTitle}</div>
              <div className="__search__status__error__desc">{i18n.invalidDesc}</div>
            </div>
          </div>
        )}
        <img src={SearchOrderBackground} className="__search__background" />
      </div>
      <div className="search-snack-alert">
        <Snackbar
          anchorOrigin={{ vertical: snackbarVertical, horizontal: 'center' }}
          open={openSuccessSnackbar}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {i18n.copySuccess}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

SearchOrder.propTypes = {
  getOrderStatus: PropTypes.func.isRequired,
}

export default SearchOrder
