import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '../../../../components/Policy/PolicyPage/TermsAndConditions'
import { TermsAndCondition as i18n } from '../PolicyContents'

function TermsAndConditions(props) {
  const history = useHistory()
  const { windowWidth } = props

  return <Layout windowWidth={windowWidth} i18n={i18n} />
}

TermsAndConditions.propTypes = {
  windowWidth: PropTypes.string.isRequired,
}

export default TermsAndConditions
