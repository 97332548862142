import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SET_SEARCH_BURGER, SET_BURGER_CONTENTS } from '../../actions/actionTypes'
import Crew from '../../assets/images/mainCrew.svg'
import YellowFlag from '../../assets/images/yellowFlag.svg'
import ReturnAndRefund from '../../assets/images/returnAndRefund.svg'
import DiscountMonitor from '../../assets/images/discountMonitor.svg'
import ContactAgentEN from '../../assets/images/contactAgentEN.svg'
import ContactAgentCN from '../../assets/images/contactAgentCN.svg'
import CompLogoLine from '../../assets/icons/indexThreeLines.svg'
const classNames = require('classnames')

function Home(props) {
  const { i18n } = props
  const dispatch = useDispatch()
  const { showingMobileBurgerContents, showingMobileSearchbar } = useSelector((state) => state.search)
  const [isNormalOrder, setIsNormalOrder] = useState(true)
  const [currentLanguage, setCurrentLanguage] = useState('')
  const history = useHistory()

  const selectNormalBtn = () => {
    setIsNormalOrder(true)
  }
  const selectExpressBtn = () => {
    setIsNormalOrder(false)
  }

  const handleQuickQuote = (value) => {
    const arr = value.props.value.split('.')
    history.push(`/quickquote?item=${arr[1]}&delivery=${isNormalOrder}`)
  }

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  return (
    <>
      <body>
        <section className="__body__contact__agent d-flex">
          <div className="agent-img">
            <a href="https://wa.me/60167758895" target="_blank">
              {window.localStorage.getItem('lang') === 'en' && (
                <img src={ContactAgentEN} at="contact-agent-en" />
              )}
              {window.localStorage.getItem('lang') === 'zh-cn' && (
                <img src={ContactAgentCN} at="contact-agent-cn" />
              )}
            </a>
          </div>
        </section>

        <section className="__body__main container-fluid">
          <div className="__body__main__title">
            <img src={CompLogoLine} alt="comp-logo-line" id="comp-logo-mobile"></img>

            <text>{i18n.mainTitle}</text>
            <img src={CompLogoLine} alt="comp-logo-line" id="comp-logo-laptop"></img>
          </div>
          <div className="__body__main__subtitle">
            <text>
              {i18n.mainSubtitleOne}
              <span id="main-subtitle-linebreaker">
                <br />
              </span>
              {i18n.mainSubtitleTwo}
            </text>
          </div>
          <div className="__body__main__order__btn mobile-inactive">
            <a href="https://wa.me/60167758895" className="btn button" target="_blank">
              {i18n.newOrder}
            </a>
          </div>
          <div className="__body__main__order__btn mobile-active">
            <button
              className="btn button"
              onClick={() => {
                dispatch({
                  type: SET_SEARCH_BURGER,
                  showingMobileSearchbar: !showingMobileSearchbar,
                })
              }}
            >
              {i18n.trackYourOrder}
            </button>
          </div>
          <div className="__body__main__crew__img">
            <img src={Crew}></img>
          </div>
        </section>

        <section className="__body__prices container-fluid container-es2move">
          <div className="__body__prices__flag">
            <div className="flag-img">
              <img src={YellowFlag} alt="yellow-flag"></img>
            </div>
            <div className="flag-text">
              <text>
                {i18n.priceFlagTitleOne}
                <br />
                {i18n.priceFlagTitleTwo}
              </text>
              <br />
              <text>{i18n.priceFlagUpTo}</text>
              <text>{i18n.priceFlagPercent}%</text>
            </div>
          </div>

          <div className="__body__prices__title title">
            <text>{i18n.priceTitle}</text>
          </div>
          <div className="__body__prices__subtitle subtitle">
            <text>{i18n.priceSubtitle}</text>
          </div>
          <div className="__body__prices__type">
            <button
              className={classNames(
                '__body__prices__type__btn',
                'left',
                'btn',
                isNormalOrder ? 'active' : 'express',
              )}
              onClick={selectNormalBtn}
            >
              {i18n.orderStandard}
            </button>
            <button
              className={classNames(
                '__body__prices__type__btn',
                'right',
                'btn',
                isNormalOrder ? '' : 'active express',
              )}
              onClick={selectExpressBtn}
            >
              {i18n.orderExpress}
            </button>
          </div>
          <div className="__body__prices__body">
            <div className="row">
              {i18n.products.map((item, ind) => (
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="product card">
                    <div className="product-title">
                      <text>{item.title}</text>
                    </div>
                    <div className="product-desc">
                      <text>{item.desc}</text>
                    </div>
                    <div className="product-img">{item.img}</div>
                    <div className="product-price">
                      <text>
                        {i18n.from}{' '}
                        <span className={classNames(isNormalOrder ? '' : 'express')}>
                          RM {isNormalOrder ? item.priceStandard : item.priceExpress}
                        </span>
                      </text>
                    </div>
                    <button
                      className={classNames('product-quote', 'btn', isNormalOrder ? '' : 'express')}
                      value={item.title}
                      onClick={() => handleQuickQuote(item.title)}
                    >
                      {i18n.orderQuickQuote}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="__body__prices__footnote">
            <text>
              {i18n.priceFootnoteOne}
              <p>
                <a href="https://wa.me/60167758895">{i18n.priceFootnoteContact}</a>
              </p>
              {i18n.priceFootnoteTwo}
            </text>
          </div>
        </section>

        <section className="__body__services container-fluid container-es2move">
          <div className="__body__services__title title">
            <text>{i18n.servicesTitle}</text>
          </div>
          <div className="__body__services__subtitle subtitle">
            <text>{i18n.servicesSubtitle}</text>
          </div>
          <div className="__body__services__body">
            <div className="row">
              {i18n.services.map((item, ind) => (
                // eslint-disable-next-line react/jsx-key
                <div className="service col-12 col-lg-4">
                  <div className="service-img">{item.img}</div>
                  <div className="service-title">
                    <text>{item.title}</text>
                  </div>
                  <div className="service-desc">
                    <text>{item.desc}</text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="__body__return container-fluid container-es2move">
          <div className="__body__return__title title">
            <text>{i18n.returnTitle}</text>
          </div>
          <div className="__body__return__subtitle subtitle">
            <text>{i18n.returnSubtitle}</text>
          </div>
          <div className="__body__return__bottom">
            <div className="row">
              <text className="col-12 col-lg-6">
                {i18n.returnDescOne}
                <br />
                <br />
                {i18n.returnDescTwo}
              </text>
              <img classname="col-12 col-lg-6" src={ReturnAndRefund} atl="return-and-refund.img"></img>
            </div>
          </div>
        </section>

        <section className="__body__discount container-fluid container-es2move">
          <div className="row">
            <img
              className="__body__discount__img col-12 col-lg-6"
              src={DiscountMonitor}
              alt="discount-monitor.img"
            ></img>
            <div className="__body__discount__body col-12 col-lg-6">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-xl-4 col-lg-5" id="percent">
                    15%
                  </div>
                  <div className="col-12 col-xl-8 col-lg-7" id="description">
                    <text>{i18n.discountDesc}</text>
                  </div>
                </div>

                <div className="row">
                  <a href="https://wa.me/60167758895" className="btn button" target="_blank">
                    {i18n.newOrder}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  )
}

Home.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Home
