const ZH_CN = {
  companyPolicies: '公司政策',
  returnRefund: '退货与退款',
  privacyPolicy: '隐私权政策',
  termsConditions: '使用条款',
  prohibitedItems: '禁止物品',
  contactInfo: '联络资讯',
  phone: '电话',
  email: '电子邮件',
  address: '地址'
}

const EN = {
  companyPolicies: 'Company Policies',
  returnRefund: 'Return & Refund',
  privacyPolicy: 'Privacy Policy',
  termsConditions: 'Terms and Conditions',
  prohibitedItems: 'Prohibited Items',
  contactInfo: 'Contact Info',
  phone: 'Phone',
  email: 'Email',
  address: 'Address'
}

export default {
  ZH_CN,
  EN
}
