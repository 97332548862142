const ZH_CN = {
  titleOne: '轻松取得',
  titleTwo: '快速报价！',
  titleThree: '',

  orderStandard: '一般',
  orderExpress: '急件',
  orderTypeDailySupplies: '民生用品',
  orderTypeFood: '食物',
  orderTypeLargeGoods: '大型货物',
  orderTypeEE: '电器产品',

  route: '送货路线',
  routeFrom: '取货地点',
  routeTo: '送达地点',
  postcode: '邮递区号',
  shipmentsDetails: '托运细节',
  weight: '重量',
  quantity: '数量',
  size: '尺寸',
  length: '长',
  width: '宽',
  height: '高',
  getPrice: '取得估价',
  clear: '清除',
  invalidPostcode: '無效的郵遞區號',
  estimatedPrice: '预估价格',
  backToQQ: '返回快速估价',
  kg: '公斤',
  cm: '公分',

  // result page
  packageType: '货物种类',
  deliveryType: '运送种类',
  contactUs: '联系客服',
  // error msg
  errPostcode: '无效的邮递区号',
  errNumber: '请输入数字',
}

const EN = {
  titleOne: 'Get a ',
  titleTwo: 'Quick Quote ',
  titleThree: 'In Easy Way !',

  orderStandard: 'Standard',
  orderExpress: 'Express',
  orderTypeDailySupplies: 'Daily Supplies',
  orderTypeFood: 'Food',
  orderTypeLargeGoods: 'Large Goods',
  orderTypeEE: 'Electric Equipment',

  route: 'Route',
  routeFrom: 'From',
  routeTo: 'To',
  postcode: 'Postcode',
  shipmentsDetails: 'Shipments Details',
  weight: 'Weight',
  quantity: 'Quantity',
  size: 'Size',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  getPrice: 'Get Price',
  clear: 'Clear',
  invalidPostcode: 'Invalid postcode.',
  estimatedPrice: 'Estimated Price',
  backToQQ: 'Back to Quick Quote',
  kg: 'kg',
  cm: 'cm',

  // result page
  packageType: 'Package Type',
  deliveryType: 'Delivery Type',
  contactUs: 'Contact Us',
  // error msg
  errPostcode: 'Invalid postcode.',
  errNumber: 'Enter the number.',
}

export default {
  ZH_CN,
  EN,
}
