const LENGTH = 5

/**
 * Get Malaysia estimated delivery duration
 * @param {string} senderState Malaysia state name
 * @param {string} receiverState Malaysia state name
 */
export const getMalaysiaEstDeliveryDuration = (senderState, receiverState) => {
  if (!MALAYSIA_DELIVERY_DURATION_TABLE[senderState]) return null
  if (!MALAYSIA_DELIVERY_DURATION_TABLE[senderState][receiverState]) return null
  return MALAYSIA_DELIVERY_DURATION_TABLE[senderState][receiverState]
}

/**
 * Get Malaysia state by postcode
 * @param {string} postcode
 */
export const getMalaysiaStateByPostcode = (postcode) => {
  if (postcode) {
    if (postcode.length === LENGTH) {
      for (const state in MALAYSIA_POSTCODE) {
        const postcodes = MALAYSIA_POSTCODE[state]
        if (postcodes.includes(postcode)) return state
      }
    }
  }
  return null
}

/**
 * Get Malaysia city by postcode
 * @param {string} postcode
 */
export const getMalaysiaCityByPostcode = (postcode) => {
  if (postcode) {
    if (postcode.length === LENGTH) {
      for (const state in MALAYSIA_STATE_CITY_POSTCODE) {
        const cities = Object.keys(MALAYSIA_STATE_CITY_POSTCODE[state])
        for (let c = 0; c < cities.length; c++) {
          const city = cities[c]
          if (MALAYSIA_STATE_CITY_POSTCODE[state][city].includes(postcode)) return city
        }
      }
    }
  }
  return null
}

/**
 * Check postcode is valid or not
 * @param {string} postcode
 */
export const isValidMalaysiaPostcode = (postcode) => {
  for (const state in MALAYSIA_POSTCODE) {
    const postcodes = MALAYSIA_POSTCODE[state]
    if (postcodes.includes(postcode)) return true
  }
  return false
}

/**
 * Check state and postcode is matched or not
 * @param {string} state
 * @param {string} postcode
 */
export const isValidMalaysiaStateAndPostcode = (state, postcode) => {
  if (!MALAYSIA_POSTCODE[state]) return false
  if (!MALAYSIA_POSTCODE[state].includes(postcode)) return false
  return true
}

/**
 * Check state and city is matched or not
 * @param {string} state
 * @param {string} city
 */
export const isValidMalaysiaStateAndCity = (state, city) => {
  if (!MALAYSIA_STATE_CITY_POSTCODE[state]) return false
  if (!Object.keys(MALAYSIA_STATE_CITY_POSTCODE[state]).includes(city)) return false
  return true
}

/**
 * Check city and postcode is matched or not
 * @param {string} state
 * @param {string} city
 * @param {string} postcode
 */
export const isValidMalaysiaCityAndPostcode = (state, city, postcode) => {
  if (!Object.keys(MALAYSIA_STATE_CITY_POSTCODE[state]).includes(city)) return false
  if (typeof (MALAYSIA_STATE_CITY_POSTCODE[state][city]) === 'string') {
    if (!MALAYSIA_STATE_CITY_POSTCODE[state][city] === postcode) return false
  } else {
    if (!MALAYSIA_STATE_CITY_POSTCODE[state][city].includes(postcode)) return false
  }
  //
  return true
}

export const MALAYSIA_POSTCODE = {
  Johor: ['86100', '83000', '86400', '83500', '83100', '83600', '83200', '83300', '83400', '83700', '81550', '80000', '80050', '80100', '80150', '80200', '80250', '80300', '80350', '80400', '80500', '80506', '80508', '80516', '80519', '80534', '80536', '80542', '80546', '80558', '80560', '80564', '80568', '80578', '80584', '80586', '80590', '80592', '80594', '80596', '80600', '80604', '80608', '80620', '80622', '80628', '80644', '80648', '80662', '80664', '80668', '80670', '80672', '80673', '80676', '80988', '81100', '81200', '81300', '81310', '81750', '79000', '79050', '79100', '79150', '79200', '79250', '79502', '79503', '79504', '79505', '79511', '79513', '79514', '79517', '79518', '79520', '79521', '79523', '79532', '79538', '79540', '79546', '79548', '79550', '79552', '79555', '79570', '79575', '79576', '79592', '79601', '79603', '79605', '79606', '79612', '79626', '79630', '79632', '79646', '79658', '79660', '79680', '79681', '79683', '81700', '81550', '81800', '86700', '86000', '81850', '86600', '86300', '86200', '81920', '81930', '81440', '81450', '81900', '81600', '81000', '81400', '84800', '84700', '84400', '84900', '86900', '86800', '86810', '84300', '84000', '84200', '84600', '84500', '84150', '82100', '82200', '82300', '81500', '82000', '85100', '86500', '85400', '85200', '85300', '85000'],
  Kedah: ['09100', '09300', '09310', '09110', '09200', '14290', '14390', '34950', '09800', '09810', '05000', '05050', '05100', '05150', '05200', '05250', '05300', '05350', '05400', '05460', '05500', '05502', '05503', '05504', '05505', '05506', '05508', '05512', '05514', '05516', '05517', '05518', '05520', '05532', '05534', '05536', '05538', '05550', '05551', '05552', '05556', '05558', '05560', '05564', '05576', '05578', '05580', '05582', '05586', '05590', '05592', '05594', '05600', '05604', '05610', '05612', '05614', '05620', '05621', '05622', '05626', '05628', '05630', '05632', '05644', '05660', '05661', '05664', '05670', '05672', '05673', '05674', '05675', '05676', '05680', '05690', '05696', '06250', '06550', '06570', '06660', '06800', '06600', '06500', '06650', '08100', '08110', '08300', '08330', '08800', '08500', '08400', '08000', '08010', '08600', '06150', '06050', '06010', '06000', '06200', '06100', '06010', '09700', '09000', '09010', '09020', '09600', '09400', '09410', '07000', '06300', '06700', '06710', '06720', '06750', '06350', '06400', '08320', '08700', '08200', '08210', '08340', '06900', '06910'],
  Kelantan: ['16020', '16030', '16050', '16060', '16070', '16090', '16300', '16310', '16320', '18300', '17700', '17600', '17610', '16500', '16450', '15000', '15050', '15100', '15150', '15200', '15300', '15350', '15400', '15500', '15502', '15503', '15504', '15505', '15506', '15508', '15512', '15514', '15516', '15517', '15518', '15519', '15520', '15524', '15529', '15532', '15534', '15536', '15538', '15540', '15546', '15548', '15550', '15551', '15556', '15558', '15560', '15564', '15570', '15572', '15576', '15578', '15582', '15586', '15590', '15592', '15594', '15596', '15600', '15604', '15608', '15609', '15612', '15614', '15616', '15622', '15623', '15626', '15628', '15630', '15632', '15634', '15644', '15646', '15648', '15658', '15660', '15661', '15664', '15670', '15672', '15673', '15674', '15676', '15680', '15690', '15720', '15990', '16010', '16100', '16150', '16400', '18000', '18050', '18500', '18400', '17000', '17010', '17020', '17030', '17040', '17050', '17060', '17070', '17200', '16700', '16800', '16810', '17500', '17510', '16080', '16200', '16210', '16040', '16250'],
  Melaka: ['78000', '76100', '78200', '78100', '78300', '76300', '77100', '77200', '77000', '77300', '77500', '77400', '75450', '76200', '75000', '75050', '75100', '75150', '75200', '75250', '75260', '75300', '75350', '75400', '75460', '75500', '75502', '75503', '75504', '75505', '75506', '75508', '75510', '75512', '75514', '75516', '75517', '75518', '75519', '75532', '75536', '75538', '75540', '75542', '75546', '75550', '75551', '75552', '75560', '75564', '75566', '75570', '75572', '75576', '75578', '75582', '75584', '75586', '75590', '75592', '75594', '75596', '75600', '75604', '75606', '75608', '75609', '75610', '75612', '75618', '75620', '75622', '75626', '75628', '75630', '75632', '75646', '75648', '75662', '75670', '75672', '75673', '75674', '75676', '75690', '76450', '76400'],
  Terengganu: ['22000', '22010', '22020', '22200', '22300', '23400', '23200', '23000', '23050', '23300', '23100', '21800', '21810', '21820', '21700', '24050', '24060', '24000', '24200', '24300', '24100', '20000', '20050', '20100', '20200', '20300', '20400', '20500', '20502', '20503', '20504', '20505', '20506', '20508', '20512', '20514', '20516', '20517', '20518', '20519', '20520', '20532', '20534', '20536', '20538', '20540', '20542', '20546', '20548', '20550', '20551', '20552', '20554', '20556', '20560', '20564', '20566', '20568', '20570', '20572', '20576', '20578', '20582', '20586', '20590', '20592', '20596', '20600', '20604', '20606', '20608', '20609', '20610', '20612', '20614', '20618', '20620', '20622', '20626', '20628', '20630', '20632', '20646', '20648', '20656', '20658', '20660', '20661', '20662', '20664', '20668', '20670', '20672', '20673', '20674', '20676', '20680', '20690', '20698', '21000', '21010', '21020', '21030', '21040', '21060', '21070', '21080', '21090', '21100', '21200', '21210', '21220', '21300', '21400', '21600', '21610', '21450', '22100', '22110', '22120', '21500'],
  Pahang: ['28700', '28730', '28740', '28750', '69000', '28600', '28610', '28620', '28200', '28340', '28380', '28300', '28310', '28320', '28330', '39100', '39200', '39000', '39007', '39009', '39010', '27030', '27000', '27010', '27020', '27040', '27050', '27060', '27070', '27090', '27150', '26150', '26190', '26050', '26090', '26180', '26300', '26310', '26320', '26330', '26340', '26350', '26360', '26370', '25000', '25050', '25100', '25150', '25200', '25250', '25300', '25350', '25500', '25502', '25503', '25504', '25505', '25506', '25508', '25509', '25512', '25514', '25516', '25517', '25518', '25520', '25524', '25529', '25532', '25534', '25536', '25538', '25540', '25546', '25548', '25550', '25551', '25552', '25556', '25558', '25560', '25564', '25570', '25576', '25578', '25582', '25584', '25586', '25590', '25592', '25594', '25596', '25598', '25600', '25604', '25606', '25608', '25609', '25610', '25612', '25614', '25620', '25622', '25626', '25628', '25630', '25632', '25644', '25646', '25648', '25656', '25660', '25661', '25662', '25670', '25672', '25673', '25674', '25676', '25690', '25990', '26010', '26040', '26060', '26070', '26080', '26100', '26140', '26250', '26200', '27300', '27310', '27200', '27210', '27100', '27650', '26400', '26410', '26420', '26430', '26440', '26450', '26460', '26485', '26490', '28100', '26500', '26690', '26600', '26610', '26620', '26630', '26640', '26650', '26660', '26680', '49000', '27400', '28800', '27600', '27610', '27620', '27630', '27670', '27660', '26900', '26800', '26810', '26820', '26700', '28050', '28500', '28400', '28000', '28010', '28020', '28030', '28040'],
  Perak: ['35950', '35500', '35300', '35800', '35820', '35600', '35900', '35910', '35000', '35400', '35350', '35700', '36500', '36100', '36600', '36750', '36400', '36700', '36200', '36300', '36000', '36008', '36010', '36020', '36030', '36110', '31600', '31610', '31850', '31900', '31910', '31920', '31950', '31700', '34300', '34310', '34350', '34200', '34400', '34250', '31000', '31200', '30000', '30010', '30020', '30100', '30200', '30250', '30300', '30350', '30450', '30500', '30502', '30503', '30504', '30505', '30506', '30508', '30510', '30512', '30516', '30517', '30518', '30519', '30520', '30524', '30532', '30534', '30536', '30540', '30542', '30546', '30548', '30550', '30551', '30552', '30554', '30556', '30560', '30564', '30570', '30576', '30580', '30582', '30586', '30590', '30592', '30594', '30596', '30600', '30604', '30606', '30609', '30610', '30612', '30614', '30620', '30621', '30622', '30626', '30628', '30630', '30632', '30634', '30644', '30646', '30648', '30656', '30658', '30660', '30661', '30662', '30664', '30668', '30670', '30673', '30674', '30676', '30682', '30690', '30988', '31350', '31400', '31450', '31500', '31650', '31300', '31550', '31560', '31250', '31800', '31750', '31150', '33600', '33000', '33010', '33020', '33030', '33040', '33800', '33700', '33500', '31050', '31100', '34500', '34510', '34520', '34850', '34600', '34650', '34750', '34140', '34100', '34120', '34130', '34700', '34000', '34010', '34020', '34030', '34800', '32400', '32700', '32500', '32200', '32300', '34900', '32040', '32040', '32000', '32100', '32610', '32600', '36800', '36810', '32900', '32800', '33300', '33310', '33320', '33200', '33400', '33410', '33420', '33100'],
  Perlis: ['02600', '02200', '01000', '01500', '01502', '01503', '01504', '01505', '01506', '01508', '01512', '01514', '01516', '01517', '01518', '01524', '01529', '01532', '01538', '01540', '01546', '01550', '01551', '01556', '01560', '01564', '01570', '01572', '01576', '01578', '01582', '01586', '01590', '01592', '01594', '01596', '01598', '01600', '01604', '01606', '01608', '01609', '01610', '01612', '01614', '01620', '01622', '01626', '01628', '01630', '01632', '01634', '01644', '01646', '01648', '01660', '01664', '01670', '01672', '01673', '01674', '01676', '01680', '01694', '02400', '02450', '02500', '02000', '02100', '02700', '02800'],
  'Pulau Pinang': ['11000', '11010', '11020', '11960', '11900', '11910', '11920', '11950', '14300', '14310', '14320', '14100', '14101', '14110', '14120', '14200', '14000', '14020', '14400', '13600', '13700', '13500', '12000', '12100', '12200', '12300', '13000', '13020', '13050', '13400', '13800', '13200', '13210', '13220', '13100', '13110', '13300', '13310', '13300', '11500', '11100', '11700', '11600', '11300', '10000', '10050', '10100', '10150', '10200', '10250', '10300', '10350', '10400', '10450', '10460', '10470', '10500', '10502', '10503', '10504', '10505', '10506', '10508', '10512', '10514', '10516', '10518', '10524', '10534', '10538', '10540', '10542', '10546', '10550', '10551', '10552', '10558', '10560', '10564', '10566', '10570', '10576', '10578', '10582', '10590', '10592', '10593', '10594', '10596', '10600', '10604', '10609', '10610', '10612', '10620', '10622', '10626', '10628', '10634', '10646', '10648', '10660', '10661', '10662', '10670', '10672', '10673', '10674', '10676', '10690', '11050', '11060', '11400', '11200', '11200', '11800'],
  'Negeri Sembilan': ['71600', '71609', '71650', '72400', '72300', '72120', '72200', '73430', '73440', '73450', '73460', '73470', '73500', '73100', '71500', '71550', '71150', '71000', '71010', '71960', '71350', '71300', '71400', '71760', '71700', '71750', '71800', '70000', '70100', '70200', '70300', '70400', '70450', '70500', '70502', '70503', '70504', '70505', '70506', '70508', '70512', '70516', '70517', '70518', '70532', '70534', '70536', '70540', '70546', '70548', '70550', '70551', '70558', '70560', '70564', '70570', '70572', '70576', '70578', '70582', '70586', '70590', '70592', '70594', '70596', '70600', '70604', '70606', '70608', '70609', '70610', '70620', '70626', '70628', '70632', '70634', '70644', '70646', '70648', '70658', '70664', '70670', '70672', '70673', '70674', '70676', '70690', '71450', '71770', '71950', '73400', '73420', '73480', '73200', '73300', '73000'],
  Putrajaya: ['62000', '62007', '62050', '62100', '62150', '62200', '62250', '62300', '62502', '62504', '62505', '62506', '62510', '62512', '62514', '62516', '62517', '62518', '62519', '62520', '62522', '62524', '62526', '62527', '62530', '62532', '62536', '62540', '62542', '62546', '62550', '62551', '62570', '62574', '62576', '62582', '62584', '62590', '62592', '62596', '62602', '62604', '62605', '62606', '62616', '62618', '62620', '62623', '62624', '62628', '62630', '62632', '62648', '62652', '62654', '62662', '62668', '62670', '62674', '62675', '62676', '62677', '62686', '62692', '62988'],
  'Kuala Lumpur': ['53100', '50000', '50050', '50088', '50100', '50150', '50200', '50250', '50300', '50350', '50400', '50450', '50460', '50470', '50480', '50490', '50500', '50502', '50504', '50505', '50506', '50507', '50508', '50512', '50514', '50515', '50519', '50528', '50529', '50530', '50532', '50534', '50536', '50540', '50544', '50546', '50548', '50550', '50551', '50552', '50554', '50556', '50560', '50562', '50564', '50566', '50568', '50572', '50576', '50578', '50580', '50582', '50586', '50588', '50590', '50592', '50594', '50596', '50598', '50599', '50600', '50603', '50604', '50605', '50608', '50609', '50610', '50612', '50614', '50620', '50621', '50622', '50623', '50626', '50632', '50634', '50636', '50638', '50640', '50642', '50644', '50646', '50648', '50650', '50652', '50653', '50656', '50658', '50660', '50661', '50662', '50664', '50666', '50668', '50670', '50672', '50673', '50676', '50677', '50678', '50680', '50682', '50684', '50688', '50694', '50782', '50988', '51000', '51100', '51200', '52000', '52100', '52200', '53000', '53100', '53200', '53300', '54000', '54100', '54200', '55000', '55100', '55200', '55300', '56000', '56100', '57000', '57100', '58000', '58100', '58200', '59000', '59100', '59200', '60000'],
  Selangor: ['48100', '68100', '48050', '48000', '48010', '48020', '48050', '48300', '42200', '41000', '41050', '41100', '41150', '41200', '41250', '41300', '41400', '41506', '41560', '41586', '41672', '41700', '42100', '42000', '42920', '42940', '42700', '42600', '42610', '42960', '42800', '42425', '42500', '42300', '45600', '45609', '45620', '45700', '45800', '45000', '45500', '46000', '46050', '46100', '46150', '46200', '46300', '46350', '46400', '46506', '46547', '46549', '46551', '46564', '46582', '46598', '46662', '46667', '46668', '46672', '46675', '46978', '47300', '47301', '47308', '47400', '47410', '47800', '47810', '47820', '47830', '47100', '47110', '47120', '47130', '47140', '47150', '47160', '47170', '47180', '47190', '43400', '43300', '40000', '40100', '40150', '40160', '40170', '40200', '40300', '40400', '40450', '40460', '40470', '40500', '40502', '40503', '40505', '40512', '40517', '40520', '40529', '40542', '40548', '40550', '40551', '40560', '40564', '40570', '40572', '40576', '40578', '40582', '40590', '40592', '40594', '40596', '40598', '40604', '40607', '40608', '40610', '40612', '40620', '40622', '40626', '40632', '40646', '40648', '40660', '40664', '40670', '40672', '40673', '40674', '40675', '40676', '40680', '40690', '47200', '47500', '47600', '47610', '47620', '47630', '47640', '47650', '47000', '45200', '45209', '45400', '45100', '45300', '63000', '63100', '63200', '63300', '43800', '64000', '43900', '43950', '68000', '43600', '43650', '43700', '43200', '43100', '43000', '43558', '43500', '44300', '44100', '44000', '44010', '44020', '44110', '44200', '48200']
}

export const MALAYSIA_DELIVERY_DURATION_TABLE = {
  Selangor: { Selangor: 1, Johor: 1, Kedah: 3, Kelantan: 3, Melaka: 3, Terengganu: 2, Pahang: 3, Perak: 3, Perlis: 2, 'Pulau Pinang': 3, 'Negeri Sembilan': 3, Putrajaya: 2, 'Kuala Lumpur': 1 },
  Johor: { Selangor: 3, Johor: 3, Kedah: 1, Kelantan: 6, Melaka: 4, Terengganu: 2, Pahang: 4, Perak: 3, Perlis: 4, 'Pulau Pinang': 6, 'Negeri Sembilan': 5, Putrajaya: 2, 'Kuala Lumpur': 3 },
  Kedah: { Selangor: 3, Johor: 3, Kedah: 6, Kelantan: 1, Melaka: 2, Terengganu: 5, Pahang: 2, Perak: 4, Perlis: 2, 'Pulau Pinang': 2, 'Negeri Sembilan': 2, Putrajaya: 4, 'Kuala Lumpur': 3 },
  Kelantan: { Selangor: 3, Johor: 3, Kedah: 4, Kelantan: 2, Melaka: 1, Terengganu: 3, Pahang: 2, Perak: 2, Perlis: 2, 'Pulau Pinang': 3, 'Negeri Sembilan': 3, Putrajaya: 4, 'Kuala Lumpur': 4 },
  Melaka: { Selangor: 2, Johor: 2, Kedah: 2, Kelantan: 5, Melaka: 3, Terengganu: 1, Pahang: 4, Perak: 3, Perlis: 3, 'Pulau Pinang': 5, 'Negeri Sembilan': 5, Putrajaya: 2, 'Kuala Lumpur': 3 },
  Terengganu: { Selangor: 3, Johor: 3, Kedah: 4, Kelantan: 2, Melaka: 2, Terengganu: 4, Pahang: 1, Perak: 2, Perlis: 3, 'Pulau Pinang': 4, 'Negeri Sembilan': 4, Putrajaya: 3, 'Kuala Lumpur': 3 },
  Pahang: { Selangor: 3, Johor: 3, Kedah: 3, Kelantan: 4, Melaka: 2, Terengganu: 3, Pahang: 2, Perak: 1, Perlis: 2, 'Pulau Pinang': 4, 'Negeri Sembilan': 4, Putrajaya: 2, 'Kuala Lumpur': 2 },
  Perak: { Selangor: 2, Johor: 2, Kedah: 4, Kelantan: 2, Melaka: 2, Terengganu: 3, Pahang: 3, Perak: 2, Perlis: 1, 'Pulau Pinang': 3, 'Negeri Sembilan': 2, Putrajaya: 3, 'Kuala Lumpur': 3 },
  Perlis: { Selangor: 3, Johor: 3, Kedah: 6, Kelantan: 2, Melaka: 3, Terengganu: 5, Pahang: 4, Perak: 4, Perlis: 3, 'Pulau Pinang': 1, 'Negeri Sembilan': 2, Putrajaya: 5, 'Kuala Lumpur': 5 },
  'Pulau Pinang': { Selangor: 3, Johor: 3, Kedah: 5, Kelantan: 2, Melaka: 3, Terengganu: 5, Pahang: 4, Perak: 4, Perlis: 2, 'Pulau Pinang': 2, 'Negeri Sembilan': 1, Putrajaya: 4, 'Kuala Lumpur': 4 },
  'Negeri Sembilan': { Selangor: 2, Johor: 2, Kedah: 2, Kelantan: 4, Melaka: 4, Terengganu: 2, Pahang: 3, Perak: 2, Perlis: 3, 'Pulau Pinang': 5, 'Negeri Sembilan': 4, Putrajaya: 1, 'Kuala Lumpur': 2 },
  Putrajaya: { Selangor: 1, Johor: 1, Kedah: 3, Kelantan: 3, Melaka: 4, Terengganu: 3, Pahang: 3, Perak: 2, Perlis: 3, 'Pulau Pinang': 5, 'Negeri Sembilan': 4, Putrajaya: 2, 'Kuala Lumpur': 1 },
  'Kuala Lumpur': { Selangor: 1, Johor: 1, Kedah: 3, Kelantan: 3, Melaka: 4, Terengganu: 3, Pahang: 3, Perak: 2, Perlis: 3, 'Pulau Pinang': 5, 'Negeri Sembilan': 4, Putrajaya: 2, 'Kuala Lumpur': 2 }
}

export const MALAYSIA_STATE_CITY_POSTCODE = {
  Johor: {
    'Ayer Hitam': '86100',
    'Batu Pahat': '83000',
    'Parit Raja': '86400',
    'Parit Sulong': '83500',
    Rengit: '83100',
    Semerah: '83600',
    Senggarang: '83200',
    'Seri Gading': '83300',
    'Seri Medan': '83400',
    'Yong Peng': '83700',
    'Gelang Patah': '81550',
    'Johor Bahru': ['80000', '80050', '80100', '80150', '80200', '80250', '80300', '80350', '80400', '80500', '80506', '80508', '80516', '80519', '80534', '80536', '80542', '80546', '80558', '80560', '80564', '80568', '80578', '80584', '80586', '80590', '80592', '80594', '80596', '80600', '80604', '80608', '80620', '80622', '80628', '80644', '80648', '80662', '80664', '80668', '80670', '80672', '80673', '80676', '80988', '81100', '81200', '81300', '81310'],
    Masai: '81750',
    Nusajaya: ['79000', '79050', '79100', '79150', '79200', '79250', '79502', '79503', '79504', '79505', '79511', '79513', '79514', '79517', '79518', '79520', '79521', '79523', '79532', '79538', '79540', '79546', '79548', '79550', '79552', '79555', '79570', '79575', '79576', '79592', '79601', '79603', '79605', '79606', '79612', '79626', '79630', '79632', '79646', '79658', '79660', '79680', '79681', '79683'],
    'Pasir Gudang': '81700',
    'Pulau Satu': '81550',
    'Ulu Tiram': '81800',
    Kahang: '86700',
    Kluang: '86000',
    'Layang- Layang': '81850',
    Paloh: '86600',
    Rengam: '86300',
    'Simpang Rengam': '86200',
    'Ayer Tawar 2': '81920',
    'Bandar Penawar': '81930',
    'Bandar Tenggara': '81440',
    'Gugusan Taib Andak': '81450',
    'Kota Tinggi': '81900',
    Pengerang: '81600',
    Kulai: '81000',
    Senai: '81400',
    'Bukit Gambir': '84800',
    Gerisek: '84700',
    'Sungai Mati': '84400',
    Tangkak: '84900',
    Endau: '86900',
    Mersing: ['86800', '86810'],
    'Bukit Pasir': '84300',
    Muar: '84000, 84200',
    Pagoh: '84600',
    Panchor: '84500',
    'Parit Jawa': '84150',
    'Ayer Baloi': '82100',
    Benut: '82200',
    Kukup: '82300',
    'Pekan Nenas': '81500',
    Pontian: '82000',
    'Batu Anam': '85100',
    Bekok: '86500',
    Chaah: '85400',
    Jementah: '85200',
    Labis: '85300',
    Segamat: '85000'
  },
  Kedah: {
    Baling: '09100',
    'Kuala Ketil': ['09300', '09310'],
    'Kuala Pegang': '09110',
    Kupang: '09200',
    'Bandar Baharu': ['14290', '14390', '34950'],
    Serdang: ['09800', '09810'],
    'Alor Setar': ['05000', '05050', '05100', '05150', '05200', '05250', '05300', '05350', '05400', '05460', '05500', '05502', '05503', '05504', '05505', '05506', '05508', '05512', '05514', '05516', '05517', '05518', '05520', '05532', '05534', '05536', '05538', '05550', '05551', '05552', '05556', '05558', '05560', '05564', '05576', '05578', '05580', '05582', '05586', '05590', '05592', '05594', '05600', '05604', '05610', '05612', '05614', '05620', '05621', '05622', '05626', '05628', '05630', '05632', '05644', '05660', '05661', '05664', '05670', '05672', '05673', '05674', '05675', '05676', '05680', '05690', '05696', '06250', '06550', '06570', '06660'],
    'Kota Sarang Semut': '06800',
    'Kuala Kedah': '06600',
    Langgar: '06500',
    'Simpang Empat': '06650',
    Bedong: ['08100', '08110'],
    Gurun: ['08300', '08330', '08800'],
    'Kota Kuala Muda': '08500',
    Merbok: '08400',
    'Sungai Petani': ['08000', '08010', '08600'],
    'Ayer Hitam': '06150',
    'Bukit Kayu Hitam': '06050',
    Changloon: '06010',
    Jitra: '06000',
    'Kepala Batas': '06200',
    Kodiang: '06100',
    'Universiti Utara Malaysia': '06010',
    Karangan: '09700',
    Kulim: ['09000', '09010', '09020'],
    Lunas: '09600',
    'Padang Serai': ['09400', '09410'],
    Langkawi: '07000',
    'Kuala Nerang': '06300',
    Pendang: ['06700', '06710', '06720', '06750'],
    'Pokok Sena': ['06350', '06400'],
    Jeniang: ['08320', '08700'],
    Sik: ['08200', '08210', '08340'],
    Yan: ['06900', '06910']
  },
  Kelantan: {
    Bachok: ['16020', '16030', '16050', '16060', '16070', '16090', '16300', '16310', '16320'],
    'Gua Musang': '18300',
    'Ayer Lanas': '17700',
    Jeli: '17600',
    'Kuala Balah': '17610',
    'Kem Desa Pahlawan': '16500',
    Ketereh: '16450',
    'Kota Bharu': ['15000', '15050', '15100', '15150', '15200', '15300', '15350', '15400', '15500', '15502', '15503', '15504', '15505', '15506', '15508', '15512', '15514', '15516', '15517', '15518', '15519', '15520', '15524', '15529', '15532', '15534', '15536', '15538', '15540', '15546', '15548', '15550', '15551', '15556', '15558', '15560', '15564', '15570', '15572', '15576', '15578', '15582', '15586', '15590', '15592', '15594', '15596', '15600', '15604', '15608', '15609', '15612', '15614', '15616', '15622', '15623', '15626', '15628', '15630', '15632', '15634', '15644', '15646', '15648', '15658', '15660', '15661', '15664', '15670', '15672', '15673', '15674', '15676', '15680', '15690', '15720', '15990', '16010', '16100', '16150'],
    Melor: '16400',
    'Kuala Krai': ['18000', '18050'],
    Machang: '18500',
    Temangan: '18400',
    'Pasir Mas': ['17000', '17010', '17020', '17030', '17040', '17050', '17060', '17070'],
    'Rantau Panjang': '17200',
    'Cherang Ruku': '16700',
    'Pasir Puteh': '16800',
    Selising: '16810',
    'Tanah Merah': ['17500', '17510'],
    Tumpat: ['16080', '16200', '16210'],
    'Wakaf Bharu': ['16040', '16250']
  },
  Melaka: {
    'Alor Gajah': '78000',
    'Durian Tunggal': '76100',
    'Kuala Sungai Baru': '78200',
    'Lubok China': '78100',
    'Masjid Tanah': '78300',
    'Sungai Udang': '76300',
    Asahan: '77100',
    Bemban: '77200',
    Jasin: '77000',
    Merlimau: '77300',
    Selandar: '77500',
    'Sungai Rambai': '77400',
    'Ayer Keroh': '75450',
    'Kem Trendak': '76200',
    Melaka: ['75000', '75050', '75100', '75150', '75200', '75250', '75260', '75300', '75350', '75400', '75460', '75500', '75502', '75503', '75504', '75505', '75506', '75508', '75510', '75512', '75514', '75516', '75517', '75518', '75519', '75532', '75536', '75538', '75540', '75542', '75546', '75550', '75551', '75552', '75560', '75564', '75566', '75570', '75572', '75576', '75578', '75582', '75584', '75586', '75590', '75592', '75594', '75596', '75600', '75604', '75606', '75608', '75609', '75610', '75612', '75618', '75620', '75622', '75626', '75628', '75630', '75632', '75646', '75648', '75662', '75670', '75672', '75673', '75674', '75676', '75690', '76450'],
    'Tanjong Kling': '76400'
  },
  Terengganu: {
    Jerteh: ['22000', '22010', '22020'],
    'Kampung Raja': '22200',
    'Kuala Besut': '22300',
    'Al Muktatfi Billah Shah': '23400',
    'Bukit Besi': '23200',
    Dungun: ['23000', '23050'],
    'Ketengah Jaya': '23300',
    Paka: '23100',
    Ajil: ['21800', '21810', '21820'],
    'Kuala Berang': '21700',
    'Ayer Puteh': '24050',
    Ceneh: '24060',
    Cukai: '24000',
    Kemasek: '24200',
    Kerteh: '24300',
    Kijal: '24100',
    'Kuala Terengganu': ['20000', '20050', '20100', '20200', '20300', '20400', '20500', '20502', '20503', '20504', '20505', '20506', '20508', '20512', '20514', '20516', '20517', '20518', '20519', '20520', '20532', '20534', '20536', '20538', '20540', '20542', '20546', '20548', '20550', '20551', '20552', '20554', '20556', '20560', '20564', '20566', '20568', '20570', '20572', '20576', '20578', '20582', '20586', '20590', '20592', '20596', '20600', '20604', '20606', '20608', '20609', '20610', '20612', '20614', '20618', '20620', '20622', '20626', '20628', '20630', '20632', '20646', '20648', '20656', '20658', '20660', '20661', '20662', '20664', '20668', '20670', '20672', '20673', '20674', '20676', '20680', '20690', '20698', '21000', '21010', '21020', '21030', '21040', '21060', '21070', '21080', '21090', '21100', '21200', '21210', '21220', '21300'],
    'Bukit Payong': '21400',
    Marang: '21600, 21610',
    Chalok: '21450',
    Permaisuri: ['22100', '22110', '22120'],
    'Sungai Tong': '21500'
  },
  Pahang: {
    Bentong: ['28700', '28730', '28740', '28750'],
    'Genting Highlands': '69000',
    Karak: ['28600', '28610', '28620'],
    'Bandar Bera': '28200',
    Kemayan: ['28340', '28380'],
    Triang: ['28300', '28310', '28320', '28330'],
    Brinchang: '39100',
    Ringlet: '39200',
    'Tanah Rata': ['39000', '39007', '39009', '39010'],
    Damak: '27030',
    Jerantut: ['27000', '27010', '27020', '27040', '27050', '27060', '27070', '27090', '27150'],
    Balok: ['26150', '26190'],
    'Bukit Goh': ['26050', '26090'],
    'Bukit Kuin': '26180',
    Gambang: ['26300', '26310', '26320', '26330', '26340', '26350', '26360', '26370'],
    Kuantan: ['25000', '25050', '25100', '25150', '25200', '25250', '25300', '25350', '25500', '25502', '25503', '25504', '25505', '25506', '25508', '25509', '25512', '25514', '25516', '25517', '25518', '25520', '25524', '25529', '25532', '25534', '25536', '25538', '25540', '25546', '25548', '25550', '25551', '25552', '25556', '25558', '25560', '25564', '25570', '25576', '25578', '25582', '25584', '25586', '25590', '25592', '25594', '25596', '25598', '25600', '25604', '25606', '25608', '25609', '25610', '25612', '25614', '25620', '25622', '25626', '25628', '25630', '25632', '25644', '25646', '25648', '25656', '25660', '25661', '25662', '25670', '25672', '25673', '25674', '25676', '25690', '25990', '26010', '26040', '26060', '26070', '26080', '26100', '26140', '26250'],
    'Sungai Lembing': '26200',
    Benta: ['27300', '27310'],
    'Kuala Lipis': ['27200', '27210'],
    'Padang Tengku': '27100',
    'Sungai Koyan': '27650',
    'Bandar Pusat Jengka': ['26400', '26410', '26420', '26430', '26440', '26450', '26460', '26485', '26490'],
    Chenor: '28100',
    Maran: '26500',
    Chini: '26690',
    Pekan: ['26600', '26610', '26620', '26630', '26640', '26650', '26660', '26680'],
    'Bukit Fraser': '49000',
    Dong: '27400',
    'Lurah Bilut': '28800',
    Raub: ['27600', '27610', '27620', '27630', '27670'],
    Sega: '27660',
    'Bandar Tun Abdul Razak': '26900',
    'Kuala Rompin': ['26800', '26810', '26820'],
    'Muadzam Shah': '26700',
    'Kuala Krau': '28050',
    Lanchang: '28500',
    Mentakab: '28400',
    Temerloh: ['28000', '28010', '28020', '28030', '28040']
  },
  Perak: {
    'Behrang Stesen': '35950',
    Bidor: '35500',
    Chenderiang: '35300',
    'Slim River': ['35800', '35820'],
    Sungkai: '35600',
    'Tanjong Malim': ['35900', '35910'],
    Tapah: '35000',
    'Tapah Road': '35400',
    Temoh: '35350',
    Trolak: '35700',
    'Ulu Bernam': '36500',
    'Bagan Datoh': '36100',
    'Chenderong Balai': '36600',
    Chikus: '36750',
    'Hutan Melintang': '36400',
    Langkap: '36700',
    Selekoh: '36200',
    'Sungai Sumun': '36300',
    'Teluk Intan': ['36000', '36008', '36010', '36020', '36030', '36110'],
    Gopeng: ['31600', '31610'],
    Jeram: '31850',
    Kampar: ['31900', '31910', '31920', '31950'],
    'Malim Nawar': '31700',
    'Bagan Serai': ['34300', '34310'],
    'Kuala Kurau': '34350',
    'Parit Buntar': '34200',
    'Simpang Ampat Semanggol': '34400',
    'Tanjong Piandang': '34250',
    'Batu Gajah': '31000',
    Chemor: '31200',
    Ipoh: ['30000', '30010', '30020', '30100', '30200', '30250', '30300', '30350', '30450', '30500', '30502', '30503', '30504', '30505', '30506', '30508', '30510', '30512', '30516', '30517', '30518', '30519', '30520', '30524', '30532', '30534', '30536', '30540', '30542', '30546', '30548', '30550', '30551', '30552', '30554', '30556', '30560', '30564', '30570', '30576', '30580', '30582', '30586', '30590', '30592', '30594', '30596', '30600', '30604', '30606', '30609', '30610', '30612', '30614', '30620', '30621', '30622', '30626', '30628', '30630', '30632', '30634', '30644', '30646', '30648', '30656', '30658', '30660', '30661', '30662', '30664', '30668', '30670', '30673', '30674', '30676', '30682', '30690', '30988', '31350', '31400', '31450', '31500', '31650'],
    'Kampung Kepayang': '31300',
    Pusing: ['31550', '31560'],
    'Tanjong Rambutan': '31250',
    'Tanjong Tualang': '31800',
    Tronoh: '31750',
    'Ulu Kinta': '31150',
    Enggor: '33600',
    'Kuala Kangsar': ['33000', '33010', '33020', '33030', '33040'],
    Manong: '33800',
    'Padang Rengas': '33700',
    Sauk: '33500',
    'Sungai Siput': ['31050', '31100'],
    'Batu Kurau': ['34500', '34510', '34520'],
    'Changkat Jering': '34850',
    Kamunting: '34600',
    'Kuala Sepetang': '34650',
    Matang: '34750',
    'Rantau Panjang': '34140',
    Selama: ['34100', '34120', '34130'],
    Simpang: '34700',
    Taiping: ['34000', '34010', '34020', '34030'],
    Trong: '34800',
    'Ayer Tawar': '32400',
    Bruas: '32700',
    'Changkat Keruing': '32500',
    Lumut: '32200',
    Pangkor: '32300',
    'Pantai Remis': '34900',
    'Seri Manjong': '32040',
    'Seri Manjung': '32040',
    Sitiawan: '32000',
    'TLDM Lumut': '32100',
    'Bandar Seri Iskandar': '32610',
    Bota: '32600',
    'Kampung Gajah': ['36800', '36810'],
    'Lambor Kanan': '32900',
    Parit: '32800',
    Gerik: ['33300', '33310', '33320'],
    Intan: '33200',
    Lenggong: ['33400', '33410', '33420'],
    'Pengkalan Hulu': '33100'
  },
  Perlis: {
    Arau: '02600',
    'Kaki Bukit': '02200',
    Kangar: ['01000', '01500', '01502', '01503', '01504', '01505', '01506', '01508', '01512', '01514', '01516', '01517', '01518', '01524', '01529', '01532', '01538', '01540', '01546', '01550', '01551', '01556', '01560', '01564', '01570', '01572', '01576', '01578', '01582', '01586', '01590', '01592', '01594', '01596', '01598', '01600', '01604', '01606', '01608', '01609', '01610', '01612', '01614', '01620', '01622', '01626', '01628', '01630', '01632', '01634', '01644', '01646', '01648', '01660', '01664', '01670', '01672', '01673', '01674', '01676', '01680', '01694', '02400', '02450', '02500'],
    'Kuala Perlis': '02000',
    'Padang Besar': '02100',
    'Simpang Ampat': ['02700', '02800']
  },
  'Pulau Pinang': {
    'Balik Pulau': ['11000', '11010', '11020'],
    'Batu Maung': '11960',
    'Bayan Lepas': ['11900', '11910', '11920', '11950'],
    'Nibong Tebal': ['14300', '14310', '14320'],
    'Simpang Ampat': ['14100', '14101', '14110', '14120'],
    'Sungai Jawi': '14200',
    'Bukit Mertajam': ['14000', '14020'],
    'Kubang Semang': '14400',
    Perai: ['13600', '13700'],
    'Permatang Pauh': '13500',
    Butterworth: ['12000', '12100', '12200', '12300', '13000', '13020', '13050', '13400', '13800'],
    'Kepala Batas': ['13200', '13210', '13220'],
    Penaga: ['13100', '13110'],
    'Tasek Gelugor': ['13300', '13310'],
    'Tasek Gelugur': '13300',
    'Ayer Itam': '11500',
    'Batu Ferringhi': '11100',
    Gelugor: '11700',
    Jelutong: '11600',
    'Penang Hill': '11300',
    'Pulau Pinang': ['10000', '10050', '10100', '10150', '10200', '10250', '10300', '10350', '10400', '10450', '10460', '10470', '10500', '10502', '10503', '10504', '10505', '10506', '10508', '10512', '10514', '10516', '10518', '10524', '10534', '10538', '10540', '10542', '10546', '10550', '10551', '10552', '10558', '10560', '10564', '10566', '10570', '10576', '10578', '10582', '10590', '10592', '10593', '10594', '10596', '10600', '10604', '10609', '10610', '10612', '10620', '10622', '10626', '10628', '10634', '10646', '10648', '10660', '10661', '10662', '10670', '10672', '10673', '10674', '10676', '10690', '11050', '11060', '11400'],
    'Tanjong Bungah': '11200',
    'Tanjung Bungah': '11200',
    'USM Pulau Pinang': '11800'
  },
  'Negeri Sembilan': {
    'Kuala Klawang': ['71600', '71609', '71650'],
    'Simpang Durian': '72400',
    'Simpang Pertang': '72300',
    'Bandar Seri Jempol': '72120',
    'Batu Kikir': '72200',
    'Pusat Bandar Palong': ['73430', '73440', '73450', '73460', '73470'],
    Rompin: '73500',
    Johol: '73100',
    'Tanjong Ipoh': ['71500', '71550'],
    Linggi: '71150',
    'Port Dickson': ['71000', '71010', '71960'],
    Kota: '71350',
    Rembau: ['71300', '71400'],
    'Bandar Enstek': '71760',
    Mantin: ['71700', '71750'],
    Nilai: '71800',
    Seremban: ['70000', '70100', '70200', '70300', '70400', '70450', '70500', '70502', '70503', '70504', '70505', '70506', '70508', '70512', '70516', '70517', '70518', '70532', '70534', '70536', '70540', '70546', '70548', '70550', '70551', '70558', '70560', '70564', '70570', '70572', '70576', '70578', '70582', '70586', '70590', '70592', '70594', '70596', '70600', '70604', '70606', '70608', '70609', '70610', '70620', '70626', '70628', '70632', '70634', '70644', '70646', '70648', '70658', '70664', '70670', '70672', '70673', '70674', '70676', '70690', '71450', '71770', '71950'],
    Gemas: ['73400', '73420', '73480'],
    Gemencheh: ['73200', '73300'],
    Tampin: '73000'
  },
  Labuan: '87000',
  Putrajaya: {
    Putrajaya: ['62000', '62007', '62050', '62100', '62150', '62200', '62250', '62300', '62502', '62504', '62505', '62506', '62510', '62512', '62514', '62516', '62517', '62518', '62519', '62520', '62522', '62524', '62526', '62527', '62530', '62532', '62536', '62540', '62542', '62546', '62550', '62551', '62570', '62574', '62576', '62582', '62584', '62590', '62592', '62596', '62602', '62604', '62605', '62606', '62616', '62618', '62620', '62623', '62624', '62628', '62630', '62632', '62648', '62652', '62654', '62662', '62668', '62670', '62674', '62675', '62676', '62677', '62686', '62692', '62988']
  },
  'Kuala Lumpur': {
    'Hulu Langat': '53100',
    'Kuala Lumpur': ['50000', '50050', '50088', '50100', '50150', '50200', '50250', '50300', '50350', '50400', '50450', '50460', '50470', '50480', '50490', '50500', '50502', '50504', '50505', '50506', '50507', '50508', '50512', '50514', '50515', '50519', '50528', '50529', '50530', '50532', '50534', '50536', '50540', '50544', '50546', '50548', '50550', '50551', '50552', '50554', '50556', '50560', '50562', '50564', '50566', '50568', '50572', '50576', '50578', '50580', '50582', '50586', '50588', '50590', '50592', '50594', '50596', '50598', '50599', '50600', '50603', '50604', '50605', '50608', '50609', '50610', '50612', '50614', '50620', '50621', '50622', '50623', '50626', '50632', '50634', '50636', '50638', '50640', '50642', '50644', '50646', '50648', '50650', '50652', '50653', '50656', '50658', '50660', '50661', '50662', '50664', '50666', '50668', '50670', '50672', '50673', '50676', '50677', '50678', '50680', '50682', '50684', '50688', '50694', '50782', '50988', '51000', '51100', '51200', '52000', '52100', '52200', '53000', '53100', '53200', '53300', '54000', '54100', '54200', '55000', '55100', '55200', '55300', '56000', '56100', '57000', '57100', '58000', '58100', '58200', '59000', '59100', '59200', '60000']
  },
  Sabah: {
    Beaufort: ['89800', '89808', '89809'],
    Membakut: '89720',
    Beluran: '90100',
    Pamol: '90400',
    Keningau: '89000',
    'Kota Kinabatangan': '90200',
    'Kota Belud': '89150',
    Inanam: '88450',
    'Kota Kinabalu': ['88000', '88100', '88200', '88300', '88400', '88450', '88460', '88500', '88502', '88504', '88505', '88506', '88508', '88510', '88512', '88514', '88516', '88518', '88520', '88526', '88527', '88532', '88534', '88538', '88540', '88546', '88550', '88551', '88552', '88554', '88556', '88558', '88560', '88562', '88564', '88566', '88568', '88570', '88572', '88576', '88580', '88582', '88586', '88590', '88592', '88594', '88596', '88598', '88600', '88602', '88604', '88606', '88608', '88609', '88610', '88612', '88614', '88617', '88618', '88620', '88621', '88622', '88624', '88626', '88628', '88630', '88632', '88634', '88644', '88646', '88648', '88656', '88658', '88660', '88661', '88662', '88670', '88672', '88673', '88675', '88676', '88680', '88690', '88988'],
    Likas: '88400',
    'Tanjung Aru': '88100',
    'Kota Marudu': '89100',
    'Kuala Penyu': '89740',
    Menumbok: '89760',
    Kudat: '89050',
    Kunak: '91200',
    'Lahad Datu': ['91100', '91150'],
    Nabawan: '89950',
    Bongawan: ['89700', '89709'],
    Papar: '89600',
    Beverly: '89500',
    Penampang: '89500',
    Putatan: '88721',
    Ranau: '89300',
    Tenghilan: '89300',
    Sandakan: ['90000', '90300', '90307'],
    Semporna: '91300',
    Sipitang: '89850',
    Tambunan: '89650',
    Tawau: '91000',
    Tamparuli: '89250',
    Tuaran: '89200'
  },
  Selangor: {
    'Batu Arang': '48100',
    'Batu Caves': '68100',
    Kuang: '48050',
    Rawang: ['48000', '48010', '48020', '48050', '48300'],
    Kapar: '42200',
    Klang: ['41000', '41050', '41100', '41150', '41200', '41250', '41300', '41400', '41506', '41560', '41586', '41672', '41700', '42100'],
    'Pelabuhan Klang': '42000',
    'Pulau Indah': '42920',
    'Pulau Ketam': '42940',
    Banting: '42700',
    Jenjarom: ['42600', '42610'],
    'Pulau Carey': '42960',
    'Tanjong Sepat': '42800',
    'Telok Panglima Garang': ['42425', '42500'],
    'Bandar Puncak Alam': '42300',
    'Bestari Jaya': ['45600', '45609', '45620'],
    'Bukit Rotan': '45700',
    Jeram: '45800',
    'Kuala Selangor': '45000',
    'Tanjong Karang': '45500',
    'Petaling Jaya': ['46000', '46050', '46100', '46150', '46200', '46300', '46350', '46400', '46506', '46547', '46549', '46551', '46564', '46582', '46598', '46662', '46667', '46668', '46672', '46675', '46978', '47300', '47301', '47308', '47400', '47410', '47800', '47810', '47820', '47830'],
    Puchong: ['47100', '47110', '47120', '47130', '47140', '47150', '47160', '47170', '47180', '47190'],
    Serdang: '43400',
    'Seri Kembangan': '43300',
    'Shah Alam': ['40000', '40100', '40150', '40160', '40170', '40200', '40300', '40400', '40450', '40460', '40470', '40500', '40502', '40503', '40505', '40512', '40517', '40520', '40529', '40542', '40548', '40550', '40551', '40560', '40564', '40570', '40572', '40576', '40578', '40582', '40590', '40592', '40594', '40596', '40598', '40604', '40607', '40608', '40610', '40612', '40620', '40622', '40626', '40632', '40646', '40648', '40660', '40664', '40670', '40672', '40673', '40674', '40675', '40676', '40680', '40690'],
    'Subang Jaya': ['47200', '47500', '47600', '47610', '47620', '47630', '47640', '47650'],
    'Sungai Buloh': '47000',
    'Sabak Bernam': ['45200', '45209'],
    Sekinchan: '45400',
    'Sungai Ayer Tawar': '45100',
    'Sungai Besar': '45300',
    Cyberjaya: ['63000', '63100', '63200', '63300'],
    Dengkil: '43800',
    Klia: '64000',
    Sepang: '43900',
    'Sungai Pelek': '43950',
    Ampang: '68000',
    'Bandar Baru Bangi': ['43600', '43650'],
    Beranang: '43700',
    Cheras: '43200',
    'Hulu Langat': '43100',
    Kajang: '43000, 43558',
    Semenyih: '43500',
    'Batang Kali': '44300',
    Kerling: '44100',
    'Kuala Kubu Baru': ['44000', '44010', '44020', '44110'],
    Rasa: '44200',
    Serendah: '48200'
  }
}
